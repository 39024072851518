import { AppState } from '../index';
import { createSelector } from '@ngrx/store';
import { selectWorkspacesCurrentSlug } from '../workspaces/workspaces.selectors';
import { AssignmentViewType, availableViewTypes } from '../../core/constants/assignment-view-type';
import { OverlaysViewSetting } from '../../core/constants/overlays-view.setting';
import {
  ganttChartProgressColumnKeys,
  ganttChartTimelineColumnKeys,
} from '../../core/constants/gantt-chart-view-settings';
import { GanttChartViewType } from '../../core/constants/gantt-chart-view-type';
import {
  ganttChartCompactViewSettings,
  ganttChartRelaxedViewSettings,
} from '../../core/models/gantt-chart-view-settings.model';
import { GanttChartCustomColumnType } from '../../core/constants/gantt-chart-custom-column-type';
import { ToggleViewMode } from '../../core/constants/toggle-view-mode';
import { initialWorkspaceUserMetaState } from './workspace-user-meta.state';
import { NodesSelectors } from '../nodes';
import { WorkspaceGroupsSelectors } from '../workspace-groups';
import { EmptyModelFactory } from '../../core/utils/empty-model-factory.util';
import { NodeTemplatesSelectors } from '../templates';
import { TagsSelectors } from '../tags';

export const selectWorkspaceUserMetaState = (state: AppState) => state.workspaceUserMeta;

export const selectUserNodeMetasById = createSelector(
  selectWorkspaceUserMetaState,
  state => state.nodeMetasById,
);

export const selectUserNodeMetasFilteredById = (nodeId: number) =>
  createSelector(selectWorkspaceUserMetaState, state => state.nodeMetasById[nodeId]);

export const selectNodeMetasWithCurrentSlug = createSelector(
  selectUserNodeMetasById,
  selectWorkspacesCurrentSlug,
  (nodeMetasById, slug) => ({ nodeMetasById, slug }),
);

export const selectAssignmentDateRange = createSelector(
  selectWorkspaceUserMetaState,
  state => state.assignmentDateRange,
);

export const selectAssignmentDateRangeWithCurrentSlug = createSelector(
  selectAssignmentDateRange,
  selectWorkspacesCurrentSlug,
  (dateRange, slug) => ({ dateRange, slug }),
);

export const selectUserMetaLastAssignmentLayout = createSelector(
  selectWorkspaceUserMetaState,
  state => state.lastAssignmentLayout,
);

export const selectUserMetaLastScheduleLayout = createSelector(
  selectWorkspaceUserMetaState,
  state => state.lastScheduleLayout,
);

export const selectUserMetaLastPlanBoardLayout = createSelector(
  selectWorkspaceUserMetaState,
  state => state.lastPlanBoardLayout,
);

export const selectGanttChartGroupBy = createSelector(
  selectWorkspaceUserMetaState,
  state => state.ganttChartGroupBy,
);

export const selectAssignmentViewType = createSelector(selectWorkspaceUserMetaState, state => {
  return availableViewTypes.indexOf(state.assignmentViewType) >= 0
    ? state.assignmentViewType
    : initialWorkspaceUserMetaState.assignmentViewType;
});

export const selectAssignmentViewTagsNodeTypes = createSelector(
  selectWorkspaceUserMetaState,
  state => state.assignmentViewTagsNodeTypes,
);

export const selectAssignmentViewWidgetsNodeTypes = createSelector(
  selectWorkspaceUserMetaState,
  state => state.assignmentViewWidgetsNodeTypes,
);

export const selectGanttChartViewType = createSelector(
  selectWorkspaceUserMetaState,
  state => state.ganttChartViewType,
);
export const selectGanttChartViewSettings = createSelector(
  selectWorkspaceUserMetaState,
  state => state.ganttChartViewSettings,
);

export const selectGanttChartDerivedColumnSettings = createSelector(
  selectGanttChartViewType,
  selectGanttChartViewSettings,
  (viewType, viewSettings) => {
    if (viewType === GanttChartViewType.relaxed) {
      return {
        timelineColumnsNumber: 4,
        progressColumnsNumber: 4,
        viewSettings: ganttChartRelaxedViewSettings,
      };
    } else if (viewType === GanttChartViewType.compact) {
      return {
        timelineColumnsNumber: 0,
        progressColumnsNumber: 0,
        viewSettings: ganttChartCompactViewSettings,
      };
    } else {
      let timelineColumnsNumber = 0;
      ganttChartTimelineColumnKeys.forEach(key => {
        if (viewSettings[key]) {
          timelineColumnsNumber++;
        }
      });
      let progressColumnsNumber = 0;
      ganttChartProgressColumnKeys
        .filter(key => key !== GanttChartCustomColumnType.assigned)
        .forEach(key => {
          if (viewSettings[key]) {
            progressColumnsNumber++;
          }
        });
      return { timelineColumnsNumber, progressColumnsNumber, viewSettings, viewType };
    }
  },
);

export const selectGanttChartViewSettingsInfo = createSelector(
  selectGanttChartViewType,
  selectGanttChartViewSettings,
  (viewType, settings) => {
    return { viewType, settings };
  },
);

export const selectOverlaysViewSettings = createSelector(
  selectWorkspaceUserMetaState,
  state => state.overlaysViewSettings,
);

export const selectIsDisplayFiguresResultsOnly = createSelector(
  selectOverlaysViewSettings,
  viewTypes => viewTypes.indexOf(OverlaysViewSetting.resultOnly) >= 0,
);

export const selectIsDisplayOverlaysWithoutScrollbars = createSelector(
  selectOverlaysViewSettings,
  viewSettings => viewSettings.indexOf(OverlaysViewSetting.displayAllWithoutScrollbars) >= 0,
);

export const selectAssignmentViewIsCompact = createSelector(selectAssignmentViewType, viewType => {
  return viewType === AssignmentViewType.compact;
});

export const selectAssignmentViewIsCustom = createSelector(selectAssignmentViewType, viewType => {
  return viewType === AssignmentViewType.custom;
});

export const selectAssignmentViewSettingInfo = createSelector(
  selectAssignmentViewTagsNodeTypes,
  selectAssignmentViewWidgetsNodeTypes,
  selectAssignmentViewIsCustom,
  selectAssignmentViewIsCompact,
  (tagsNodeTypes, widgetsNodeTypes, isCustom, isCompact) => {
    return {
      tagsNodeTypes,
      widgetsNodeTypes,
      isCustom,
      isCompact,
    };
  },
);

export const selectAssignmentSorting = createSelector(
  selectWorkspaceUserMetaState,
  (state): string => state.assignmentSorting,
);

export const selectAwaitingAssignmentsViewMode = createSelector(
  selectWorkspaceUserMetaState,
  (state): ToggleViewMode => state.awaitingAssignmentsViewMode,
);

export const selectAssignmentHeaderDetailsViewMode = createSelector(
  selectWorkspaceUserMetaState,
  (state): ToggleViewMode => state.assignmentHeaderDetailsViewMode,
);

export const selectAssignmentAssetProfilePhotoViewMode = createSelector(
  selectWorkspaceUserMetaState,
  (state): ToggleViewMode => state.assignmentAssetProfilePhotoViewMode,
);

export const selectAssignmentAssetsAsListViewMode = createSelector(
  selectWorkspaceUserMetaState,
  (state): ToggleViewMode => state.assignmentAssetsAsListViewMode,
);

export const selectAssignmentOverlayViewMode = createSelector(
  selectWorkspaceUserMetaState,
  state => {
    return state.assignmentOverlayViewMode;
  },
);

export const selectContentPanelTagsViewMode = createSelector(
  selectWorkspaceUserMetaState,
  state => state.contentPanelTagsViewMode,
);

export const selectContentPanelWidgetsViewMode = createSelector(
  selectWorkspaceUserMetaState,
  state => state.contentPanelWidgetsViewMode,
);

export const selectContentPanelLayoutTemplateViewMode = createSelector(
  selectWorkspaceUserMetaState,
  state => state.contentPanelLayoutTemplateViewMode,
);

export const selectSidebarLeftVisibility = createSelector(
  selectWorkspaceUserMetaState,
  state => state.sidebarLeft,
);

export const selectSidebarRightVisibility = createSelector(
  selectWorkspaceUserMetaState,
  state => state.sidebarRight,
);

export const selectTableProjectIds = createSelector(
  selectWorkspaceUserMetaState,
  state => state.tableProjectIds,
);

export const selectTableProjects = createSelector(
  selectTableProjectIds,
  NodesSelectors.selectNodesById,
  (projectIds, nodesById) => projectIds.map(id => nodesById[id]).filter(n => n != null),
);

export const selectTableAssetIds = createSelector(
  selectWorkspaceUserMetaState,
  state => state.tableAssetIds,
);

export const selectTableAssets = createSelector(
  selectTableAssetIds,
  NodesSelectors.selectNodesById,
  (assetIds, nodesById) => assetIds.map(id => nodesById[id]).filter(n => n != null),
);

export const selectTableTagIds = createSelector(
  selectWorkspaceUserMetaState,
  state => state.tableTagIds,
);

export const selectTableReportPeriod = createSelector(
  selectWorkspaceUserMetaState,
  state => state.tableReportPeriod,
);

export const selectTableTags = createSelector(
  selectTableTagIds,
  TagsSelectors.selectTagsById,
  (tagIds, tagsById) => tagIds.map(id => tagsById[id]).filter(n => n != null),
);

export const selectTableGroupIds = createSelector(
  selectWorkspaceUserMetaState,
  state => state.tableGroupIds,
);

export const selectTableGroups = createSelector(
  selectTableGroupIds,
  WorkspaceGroupsSelectors.selectWorkspaceGroupsById,
  (groupIds, groupsById) =>
    groupIds
      .map(id => {
        if (id == null) {
          return EmptyModelFactory.workspaceGroupModel();
        } else {
          return groupsById[id];
        }
      })
      .filter(g => g != null),
);

export const selectTableNodeTemplateIds = createSelector(
  selectWorkspaceUserMetaState,
  state => state.tableNodeTemplateIds,
);

export const selectTableNodeTemplates = createSelector(
  selectTableNodeTemplateIds,
  NodeTemplatesSelectors.selectNodeTemplatesById,
  (nodeTemplateIds, nodeTemplatesById) =>
    nodeTemplateIds.map(id => nodeTemplatesById[id]).filter(nt => nt != null),
);
