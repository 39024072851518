import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../store';
import { Router } from '@angular/router';
import { selectWorkspaceLinkViewGroups } from '../../../store/workspace-groups/workspace-link-groups.selectors';
import { selectCurrentWorkspace } from '../../../store/workspaces/workspaces.selectors';
import { WorkspaceGroupModel } from '../../../core/models/workspace-group.model';
import { NoDataTypeIds } from '../../../core/constants/no-data-settings';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-workspace-links-modal',
  templateUrl: 'workspace-links-modal.component.html',
  styleUrls: ['workspace-links-modal.component.scss'],
})
export class WorkspaceLinksModalComponent implements OnInit, OnDestroy {
  @Input()
  public instructions: string;

  @Input()
  public instructionsTemplate: TemplateRef<any>;
  public noDataTypeIds = NoDataTypeIds;
  public readonly modalTitle = 'Resource Center';
  public readonly iconUrl: string = 'assets/img/new-logo/Z-Chat.png'; // Default icon path

  private readonly subscription: Subscription;
  public slug: string;
  public groups: WorkspaceGroupModel[] = [];
  public smallScreen = false;
  public active = null;
  constructor(
    private router: Router,
    private store: Store<AppState>,
    private responsive: BreakpointObserver,
  ) {
    this.subscription = new Subscription();
  }

  public ngOnInit() {
    this.subscription.add(
      this.store.pipe(select(selectCurrentWorkspace)).subscribe(workspace => {
        this.slug = workspace?.slug;
      }),
    );

    this.subscription.add(
      this.store.pipe(select(selectWorkspaceLinkViewGroups)).subscribe(groups => {
        this.groups = groups;
      }),
    );

    this.subscription.add(
      this.responsive.observe([Breakpoints.Small, Breakpoints.XSmall]).subscribe(result => {
        this.smallScreen = result.matches;
      }),
    );
  }

  public manageLinks(event) {
    this.router.navigate([`/ws/${this.slug}/settings/links`]);
  }

  goBack(): void {
    this.active = null;
  }

  public trackBy(index, item) {
    return item.id;
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
