import { AppState } from '../index';
import { createSelector } from '@ngrx/store';
import { WorkspaceUserMetaSelectors } from '../workspace-user-meta';
import { TimesheetModel } from '../../core/models/timesheet.model';

export const selectTimesheetsState = (state: AppState) => state.timesheets;

export const selectTimesheetsById = createSelector(
  selectTimesheetsState,
  state => state.timesheetsById,
);

export const selectTimesheetsByNodeId = createSelector(
  selectTimesheetsState,
  state => state.timesheetsByNodeId,
);

export const selectTimesheetsByParentNodeId = createSelector(
  selectTimesheetsState,
  state => state.timesheetsByParentNodeId,
);

export const selectTimesheetsFilterByReportPeriod = createSelector(
  selectTimesheetsByNodeId,
  WorkspaceUserMetaSelectors.selectTableReportPeriod,
  (timesheetsByNodeId, selectedReportPeriod) => {
    // return timesheets filter by selectedReport period filter
    const allTimesheets: TimesheetModel[] = Object.values(timesheetsByNodeId).flat();
    if (selectedReportPeriod !== '') {
      return allTimesheets?.filter(item => item?.reportPeriod === selectedReportPeriod);
    }
    return allTimesheets;
  },
);
