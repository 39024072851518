<ng-template #stepInfo>
  <ng-container *ngIf="selectedItem && smallScreen">
    <button type="button" class="btn btn-secondary fal fa-chevron-left ml-2" (click)="goBack()">
      <!-- Screen reader only text for accessibility -->
      <span class="sr-only">Back</span>
    </button>
  </ng-container>
</ng-template>

<app-modal [title]="modalTitle" [iconUrl]="iconUrl" [stepTemplate]="stepInfo">
  <app-modal-instructions
    [html]="instructions"
    [template]="instructionsTemplate"
  ></app-modal-instructions>

  <div class="row">
    <ng-container *ngIf="!smallScreen; else smallScreenContent">
      <div class="col-7 layout-library-left">
        <ng-container *ngTemplateOutlet="list"></ng-container>
      </div>
      <div class="col-5 layout-library-right">
        <ng-container *ngTemplateOutlet="details"></ng-container>
      </div>
    </ng-container>

    <ng-template #smallScreenContent>
      <ng-container *ngIf="!selectedItem; else smallDetails">
        <div class="col-12 layout-library-left">
          <ng-container *ngTemplateOutlet="list"></ng-container>
        </div>
      </ng-container>
      <ng-template #smallDetails>
        <div class="col-12 layout-library-right">
          <ng-container *ngTemplateOutlet="details"></ng-container>
        </div>
      </ng-template>
      <!--  -->
    </ng-template>
  </div>
</app-modal>

<ng-template #list>
  <div class="layout-library-left--inner">
    <div class="row item-picked__search-bar align-items-center justify-content-center">
      <!-- <div class="col-2">
      <button type="button" class="btn btn-sm btn-link" (click)="clearSelection()">
        Clear
      </button>
    </div> -->
      <div class="col-6 m-3">
        <app-search-box
          [searchKeyword]="keywords"
          sizeType="sm"
          (searchChanged)="search($event)"
        ></app-search-box>
      </div>
    </div>

    <div class="row item-list m-0 pb-4">
      <div class="row m-0 w-100 ">
        <label>Workspace Templates</label>
      </div>
      <div class="row m-0 justify-content-between w-100">
        <div
          *ngFor="let item of workspaceTemplates; trackBy: trackBy"
          class="col-3 item layout-mr-xl"
          (click)="selectItem(item, 'workspace')"
          type="button"
          [ngClass]="{ 'item--selected': selectedItem?.id === item?.id }"
        >
          <!-- left side should have a image and right side - label -->
          <div class="row h-100 w-100 align-items-center justify-content-center">
            <div
              class="col-4 h-100 w-100 d-flex align-items-center justify-content-center"
              [ngClass]="['assignment-primary-tag--' + (item?.colorTheme | colorString)]"
            >
              <div
                *ngIf="item?.icon; else noImage"
                class="dashboard-card__icon fal font-size-xx-large"
                [ngClass]="[
                  'fa-' + (item?.icon | iconString),
                ]"
              ></div>
              <ng-template #noImage>
                <div class="layout-picker_item-thumbnail layout-picker_item-thumbnail-no-image">
                  <i class="fal fa-question layout-card-icon"></i>
                </div>
              </ng-template>
            </div>
            <div class="col-8 h-100 w-100 d-flex align-items-center">
              <label class="layout-picker_item-label text-truncate">
                {{ item?.title }}
              </label>
            </div>
          </div>
        </div>

        <ng-container *ngIf="!workspaceTemplates?.length">
          <app-no-data [type]="noDataTypeIds.layoutPickerWorkspaceTemplates"></app-no-data>
        </ng-container>
      </div>

      <div class="row mt-3 m-0 w-100 ">
        <label>Library Templates</label>
      </div>
      <div class="row m-0 justify-content-between w-100">
        <div
          *ngFor="let item of filteredTemplates; trackBy: trackBy"
          class="col-3 item layout-mr-xl"
          [ngClass]="{
            'item--installed': item?.installed
          }"
          (click)="selectItem(item, 'layout')"
          type="button"
          [ngClass]="{ 'item--selected': selectedItem?.id === item?.id }"
        >
          <!-- left side should have a image and right side - label -->
          <div class="row h-100 w-100 align-items-center justify-content-center">
            <div
              class="col-4 h-100 w-100 d-flex align-items-center justify-content-center"
              [ngClass]="['assignment-primary-tag--' + (item?.colorTheme | colorString)]"
            >
              <div
                *ngIf="item?.icon; else noImage"
                class="dashboard-card__icon fal font-size-xx-large"
                [ngClass]="[
                  'fa-' + (item?.icon | iconString),
                ]"
              ></div>
              <ng-template #noImage>
                <div class="layout-picker_item-thumbnail layout-picker_item-thumbnail-no-image">
                  <i class="fal fa-question layout-card-icon"></i>
                </div>
              </ng-template>
            </div>
            <div class="col-8 h-100 w-100 d-block align-content-center">
              <div class="layout-picker_item-label text-truncate">
                {{ item?.title }}
              </div>
              <div class="badge badge-danger" *ngIf="item?.installed">Installed</div>
            </div>
          </div>
        </div>

        <ng-container *ngIf="!filteredTemplates?.length">
          <app-no-data [type]="noDataTypeIds.layoutPickerLayoutTemplates"></app-no-data>
        </ng-container>
      </div>

      <app-content-loader scope="layout-content"></app-content-loader>
    </div>
  </div>
</ng-template>

<ng-template #details>
  <div class="row h-100">
    <div class="col-12">
      <ng-container *ngIf="selectedItem; else noItemSelected">
        <app-layout-block
          [item]="selectedItem"
          [type]="selectedItemType"
          [readonly]="true"
          [layout]="'large'"
        ></app-layout-block>
      </ng-container>

      <ng-template #noItemSelected>
        <div class="layout-library-right_no_data">
          <app-no-data [type]="noDataTypeIds.layoutPickerNoItemSelected"></app-no-data>
        </div>
      </ng-template>
    </div>
  </div>
</ng-template>
