<div
  class="form-group m-1 position-static"
  ngbDropdown
  #calendarPanel="ngbDropdown"
  [container]="container"
>
  <div class="input-group">
    <div
      class="date-picker_label_with_toggle"
      [ngClass]="{
        'date-picker_label_with_toggle--readonly': true
      }"
    >
      <span *ngIf="showFilterTitle" class="mr-1">Report Period</span>
      {{
        showFilterTitle
          ? monthYearCtrl.value?.year && monthYearCtrl.value?.month
            ? ': ' + getDisplayValue()
            : ''
          : monthYearCtrl.value?.year && monthYearCtrl.value?.month
          ? getDisplayValue()
          : '-'
      }}
    </div>
    <div class="input-group-append date-picker_form-group--hidden">
      <button class="btn btn-outline-secondary dropdown-toggle-split" ngbDropdownToggle>
        <div class="calendar" aria-hidden="true"></div>
      </button>
    </div>
  </div>
  <div ngbDropdownMenu class="dropdown-menu">
    <div class="row">
      <button class="btn btn-link col-4" (click)="addYear($event, -1)">
        <span class="ngb-dp-navigation-arrow left"></span>
      </button>
      <button *ngIf="!isyear" class="btn btn-link col-4" (click)="showYear($event, true)">
        {{ monthYearCtrl.value?.year ?? currentYear }}
      </button>
      <button *ngIf="isyear" class="btn btn-link col-4" (click)="showYear($event, false)">
        {{ incr + 1 }}-{{ incr + 10 }}
      </button>
      <button class="btn btn-link col-4" (click)="addYear($event, +1)">
        <span class="ngb-dp-navigation-arrow right"></span>
      </button>
      <div
        *ngFor="let month of months; let i = index; let first = first; let last = last"
        class="col-4"
        (click)="selectYearMonth($event, i)"
      >
        <div
          [ngClass]="{
            select: !isyear ? i + 1 == monthYearCtrl.value?.month : i + incr == monthYearCtrl.value?.year,
            outrange: isyear && (first || last)
          }"
          class="card-body"
        >
          <span *ngIf="!isyear">{{ month }}</span>
          <span *ngIf="isyear">{{ i + incr }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
