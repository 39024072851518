<div class="lb-scroll-bar">
  <div class="row lb-scroll-bar--icon-desc">
    <div
      *ngIf="item?.profile?.src"
      class="col-12 lb-scroll-bar--icon"
      [ngStyle]="{ 'background-image': 'url(' + item?.profile?.src + ')' }"
      alt="Template Image"
      (click)="showImageModal()"
    ></div>
  </div>

  <div class="col lb-scroll-bar--desc">
    <div class="row m-0 lb-header">
      <!-- <div class="col-auto p-0 lb-header&#45;&#45;icon">
         <span class="fal mr-2" [ngClass]="['fa-' + (item?.icon | iconString)]"></span>
       </div>-->
      <div class="col mt-1">
        <div class="row align-items-center">
          <span
            class="fal mr-2 text-truncate"
            [ngClass]="['fa-' + (item?.icon | iconString), 'text-truncate']"
          ></span>
          <label class="text-truncate">
            {{ item?.title }}
          </label>
        </div>
      </div>

      <div class="col-auto mt-1" *ngIf="type === 'layout'">
        <button class="btn btn-primary" (click)="installLayout()" [disabled]="item?.installed">
          Use Template
        </button>
        <button
          *ngIf="loggedInUser | can: PermissionType.deleteLayoutTemplate"
          class="btn btn-danger ml-2"
          data-toggle="tooltip"
          data-placement="top"
          title="delete layout"
          (click)="deleteLayout()"
        >
          <i class="fal fa-trash"></i>
        </button>
      </div>
    </div>
    <!--<label>Template Description</label>-->
    <p [innerHTML]="item?.tooltip || '-'"></p>
  </div>

  <div class="row m-0 my-3 lb-scroll-bar--temp-title">
    <label>Card Layout</label>
  </div>

  <app-to-card-layout
    [selectedTemplate]="item"
    [selectedTemplates]="selectedTemplates"
    [selectedAssetTemplates]="selectedAssetTemplates"
    [selectedFormTemplates]="selectedFormTemplates"
    [groups]="groupsAd"
    [primaryTagGroups]="primaryTagGroups"
    [disabled]="type === 'layout'"
  ></app-to-card-layout>

  <div class="row m-0 my-3 lb-scroll-bar--temp-title">
    <label>Z-Apps</label>
  </div>

  <app-to-z-apps
    [selectedTemplate]="item"
    [selectedTemplates]="selectedTemplates"
    [selectedAssetTemplates]="selectedAssetTemplates"
    [selectedFormTemplates]="selectedFormTemplates"
    [stampTagGroups]="stampTagGroups"
    [calculationWidgets]="calculationWidgets"
    [calIds]="calIds"
    [disabled]="type === 'layout'"
    [showOptions]="['Financials', 'Forms', 'Status Tags']"
  >
  </app-to-z-apps>
</div>
