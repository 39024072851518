import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { ErrorsActions } from '../errors';
import { NodeRateValuesActions } from './index';
import { NodeRateValueService } from '../../core/services/node-rate-value.service';
import { NodeRateValueModel } from '../../core/models/node-rate-value.model';

@Injectable()
export class NodeRateValuesEffects {
  constructor(private actions$: Actions, private nodeRateValueService: NodeRateValueService) {}

  addRateValueRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodeRateValuesActions.addNodeRateValueRequest),
      mergeMap(action =>
        this.nodeRateValueService
          .addRateValue(
            action.nodeId,
            action.nodeRateId,
            //action.groupId,
            action.reportPeriod,
            action.quantity,
          )
          .pipe(
            map((rateValue: NodeRateValueModel) => {
              return NodeRateValuesActions.addNodeRateValueSuccess({ rateValue });
            }),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({ error }));
            }),
          ),
      ),
    ),
  );

  updateRateValueRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodeRateValuesActions.updateNodeRateValueRequest),
      mergeMap(action =>
        this.nodeRateValueService.updateRateValue(action.rateValueId, action.rateValueProps).pipe(
          map((rateValue: NodeRateValueModel) => {
            return NodeRateValuesActions.addNodeRateValueSuccess({ rateValue });
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  deleteRateValueRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodeRateValuesActions.deleteNodeRateValueRequest),
      mergeMap(action =>
        this.nodeRateValueService.removeRateValue(action.nodeId, action.rateValueId).pipe(
          switchMap(() => {
            return EMPTY;
          }),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );
}
