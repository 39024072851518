<!-- Sidebar Header: contains navigation and channel information -->
<div class="sidebar-header__body sidebar-header__body--chat">
  <!-- First Column: Back button for navigation -->
  <div class="sidebar-header__first-col">
    <button
      type="button"
      class="sidebar-header__btn-back btn btn-secondary fal fa-chevron-left"
      (click)="goToAllChannels()"
    >
      <!-- Screen reader only text for accessibility -->
      <span class="sr-only">Back</span>
    </button>
  </div>

  <!-- Center Column: Displays current channel's title and subtitle if a channel is selected -->
  <div class="sidebar-header__center-col" *ngIf="channel">
    <!-- Channel title, processed through a custom pipe for display -->
    <h2 class="sidebar-header__title" *ngIf="channel | chatChannelTitle">
      {{ channel | chatChannelTitle }}
    </h2>
    <!-- Channel subtitle, also processed through a custom pipe -->
    <h4 class="sidebar-header__subtitle" *ngIf="channel | chatChannelTypeLabel">
      {{ channel | chatChannelTypeLabel }}
    </h4>
  </div>

  <!-- Last Column: Shows badge with count of new comments -->
  <div class="sidebar-header__last-col">
    <div class="nav-chat__badge badge badge-pill badge--inverted-secondary hide-on-sm">
      {{ (channel?.commentCount || 0) + ' messages' }}
    </div>
  </div>
</div>

<!-- Main Chat Section -->
<div class="chat">
  <!-- Conditionally render chat or a message if no channel is selected -->
  <ng-container *ngIf="channel; else noSelectedChannel">
    <!-- Chat Body: Container for comments -->
    <div class="chat__body" #commentsContainer>
      <!-- Iterating through each comment -->
      <div
        #commentElement
        class="chat__comment-container"
        *ngFor="let comment of comments; trackBy: trackBy"
      >
        <!-- Individual chat comment component with actions and edit capabilities -->
        <app-chat-comment
          [comment]="comment"
          [fromRight]="currentUserId === comment.createdBy?.userId"
          [selectedCommentForEdit]="selectedCommentForEdit"
          (actionFired)="onCommentActionFired($event, comment)"
        ></app-chat-comment>
      </div>

      <!-- No data template when there are no comments -->
      <ng-container *ngIf="!comments?.length">
        <app-no-data [type]="noDataTypeIds.chatComments"></app-no-data>
      </ng-container>
    </div>
  </ng-container>

  <!-- Template displayed when no channel is selected -->
  <ng-template #noSelectedChannel>
    <div class="w-100 h-100 text-center d-flex align-items-center">
      <div class="card-body">
        <i class="fal fa-comment-slash feedback-icon"></i>
        <h5 class="card-title">No channel selected</h5>
        <p class="card-text">Select a channel to start chatting</p>
      </div>
    </div>
  </ng-template>

  <!-- Chat Comment Input: Component for adding new comments and attachments -->
  <app-chat-comment-new
    *ngIf="channel"
    class="chat__footer"
    [selectedCommentForEdit]="selectedCommentForEdit"
    (addCommentEvent)="addComment($event)"
    (addAttachmentEvent)="addAttachment($event)"
  ></app-chat-comment-new>
</div>
