<ng-container [ngSwitch]="cell?.value?.widget?.widgetType">
  <app-z-table-editable-date
    *ngSwitchCase="WidgetTypes.date"
    [field]="column.id"
    [column]="column"
    [row]="row"
    [cell]="cellNew"
    (endEdit)="onEndEdit($event)"
    [tableEditable]="tableEditable"
  >
  </app-z-table-editable-date>

  <app-z-table-editable-month-year
    *ngSwitchCase="WidgetTypes.MonthYear"
    [field]="column.id"
    [column]="column"
    [row]="row"
    [cell]="cellNew"
    (endEdit)="onEndEdit($event)"
    [tableEditable]="tableEditable"
  >
  </app-z-table-editable-month-year>

  <app-z-table-editable-number
    *ngSwitchCase="WidgetTypes.number"
    [field]="column.id"
    [column]="column"
    [row]="row"
    [cell]="cellNew"
    (endEdit)="onEndEdit($event)"
    [tableEditable]="tableEditable"
  >
  </app-z-table-editable-number>

  <app-z-table-editable-assignment-checkbox
    *ngSwitchCase="WidgetTypes.checkbox"
    [field]="column.id"
    [column]="column"
    [row]="row"
    [cell]="cellNew"
    (endEdit)="onEndEdit($event)"
    [tableEditable]="tableEditable"
  >
  </app-z-table-editable-assignment-checkbox>

  <app-z-table-editable-text
    *ngSwitchCase="WidgetTypes.textbox"
    [field]="column.id"
    [column]="column"
    [row]="row"
    [cell]="cellNew"
    (endEdit)="onEndEdit($event)"
    [tableEditable]="tableEditable"
  >
  </app-z-table-editable-text>

  <app-z-table-editable-time
    *ngSwitchCase="WidgetTypes.time"
    [field]="column.id"
    [column]="column"
    [row]="row"
    [cell]="cellNew"
    (endEdit)="onEndEdit($event)"
    [tableEditable]="tableEditable"
  >
  </app-z-table-editable-time>

  <app-z-table-editable-address
    *ngSwitchCase="WidgetTypes.address"
    [field]="column.id"
    [column]="column"
    [row]="row"
    [cell]="cellNew"
    (endEdit)="onEndEdit($event)"
    [tableEditable]="tableEditable"
  >
  </app-z-table-editable-address>

  <app-z-table-editable-text
    [tableEditable]="tableEditable"
    *ngSwitchDefault
    [field]="column.id"
    [column]="column"
    [row]="row"
    [cell]="cellNew"
    (endEdit)="onEndEdit($event)"
  >
  </app-z-table-editable-text>
</ng-container>
