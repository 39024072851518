import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';
import { NodeType } from '../../../core/constants/node-type';
import { urlValidationPattern } from '../../../core/constants/validation-patterns';
import { WidgetType } from '../../../core/constants/widget-type';
import { NodeTemplateWidgetModel } from '../../../core/models/node-template-widget.model';
import { NodeTemplateModel } from '../../../core/models/node-template.model';
import { NodeModel } from '../../../core/models/node.model';
import { NodeService } from '../../../core/services/node.service';
import { AppState } from '../../../store';
import { NodeWidgetsActions } from '../../../store/node-widgets';
import { NodesActions, NodesSelectors } from '../../../store/nodes';
import { addressFieldRequiredValidator } from '../../components/address-field/address-field.validators';
import { NodePickerComponent } from '../node-picker/node-picker.component';
import { AssignmentViewType } from '../../../core/constants/assignment-view-type';
import { CustomViewNodeType } from '../../../core/constants/custom-view-node-type';
import { CardLayoutType } from '../../../core/constants/card-layout-type';
import { BehaviourType } from '../../../core/constants/behaviour-type';
import { NodeViewActions } from '../../../store/node-view';
import { NodeViewPopoverComponent } from '../../../features/workspace/modals/node-view-popover/node-view-popover.component';
import { OverlayPopoverService } from '../../components/overlay-popover/overlay-popover.service';
import { NodeAssetPickerComponent } from '../node-asset-picker/node-asset-picker.component';
import * as moment from 'moment';
import { jsPDF, jsPDFOptions } from 'jspdf';
import html2canvas from '@html2canvas/html2canvas';
import { FilePaths } from '../../../core/constants/file-paths';
import { NoDataTypeIds } from '../../../core/constants/no-data-settings';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { UserModel } from '../../../core/models/user.model';
import { selectCurrentUser } from '../../../store/auth/auth.selectors';
import { NodeWidgetRowModel } from '../../../core/models/node-widget-row.model';
import { NodeWidgetValueModel } from '../../../core/models/node-widget-value.model';

@Component({
  selector: 'app-forms-edit-panel',
  templateUrl: './forms-edit-panel.component.html',
  styleUrls: ['./forms-edit-panel.component.scss'],
})
export class FormsEditPanelComponent extends NodePickerComponent implements OnInit, OnDestroy {
  public readonly NodeType = NodeType;
  @Input() assignment: NodeModel;

  public currentStep = 1;
  public stepLength = 3;
  public title = 'Forms'; //'Select Elements';
  public iconUrl: string = 'assets/img/new-logo/Z-Chat.png'; // Default icon path
  public viewType = AssignmentViewType.relaxed;
  public customViewNodeType = CustomViewNodeType.element;
  public cardLayout = CardLayoutType.twoColumns;
  public isPrivateView = true;
  public isAddAnotherForm = false;
  // pdfTitle should be current date time + assignment.pdf
  public pdfTitle: string = `assignment_card_${moment().format('YYYY-MM-DD_HH-mm-ss')}.pdf`;
  public widgetChanges: { widget: NodeTemplateWidgetModel; value: any }[] = [];
  public formEditEnabled = false;
  readonly filePathConstant: typeof FilePaths = FilePaths;
  // step 2
  form: UntypedFormGroup;
  user: UserModel;

  set widgets(nodeWidgets: NodeTemplateWidgetModel[]) {
    if (nodeWidgets) {
      this.form = this.toFormGroup(nodeWidgets);
      this.form.disable();
      this.formEditEnabled = false;
    }
    this._widgets = nodeWidgets;
  }

  get widgets(): NodeTemplateWidgetModel[] {
    return this._widgets;
  }

  private _widgets: NodeTemplateWidgetModel[];
  public noDataTypeIds = NoDataTypeIds;
  public smallScreen = false;

  constructor(
    public activeModal: NgbActiveModal,
    protected modalService: NgbModal,
    protected store: Store<AppState>,
    private nodeService: NodeService,
    private popoverService: OverlayPopoverService,
    private responsive: BreakpointObserver,
  ) {
    super(activeModal, modalService, store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscription.add(
      this.nodeService.getSelectedLayoutAssignment().subscribe((assignment: NodeModel) => {
        if (assignment?.elementGroups?.length && this.assignment?.id === assignment?.id) {
          this.assignment = assignment;
          if (this.nodeTemplates[0]?.behaviourType === BehaviourType.single) {
            this.setElementWidgetsEditStep();
          }
        }
      }),
    );

    this.subscription.add(
      this.responsive.observe([Breakpoints.Small, Breakpoints.XSmall]).subscribe(result => {
        this.smallScreen = result.matches;
      }),
    );

    this.subscription.add(
      this.store.pipe(select(selectCurrentUser)).subscribe(user => {
        this.user = user;
      }),
    );
  }

  getWidgetValueFromWidgetRows(widget: NodeTemplateWidgetModel): any {
    const widgetRows: NodeWidgetRowModel[] = this.assignment?.reference?.widgetRows?.length
      ? this.assignment?.reference?.widgetRows
      : [];
    if (widgetRows.length) {
      const widgetRow = widgetRows.find(w => w.nodeId === this.assignment?.reference?.id);
      if (widgetRow && widgetRow?.values?.length) {
        const values: NodeWidgetValueModel[] = widgetRow.values;
        const value = values.find(v => v.widgetId === widget.id);
      }
    }
    return null;
  }

  goBack(): void {
    this.currentStep = 1;
    this.formEditEnabled = false;
    this.title = 'Forms';
    this.selected = [];
  }

  makePublicOrPrivate(): void {
    this.isPrivateView = !this.isPrivateView;
  }

  openAssignmentElementsPopover(template: NodeTemplateModel) {
    if (this.assignment.readOnly === true) {
      return;
    }

    if (template?.behaviourType === BehaviourType?.multiple) {
      this.currentStep = 1;
      this.openElementPicker(template);
    } else if (template?.behaviourType === BehaviourType?.single) {
      this.openElementAndWidgetPicker(template);
    }
  }

  openElementPicker(template: NodeTemplateModel): void {
    this.nodeTemplates = [template];
    const modelRef = this.modalService.open(NodeAssetPickerComponent, {
      size: 'xl',
      backdrop: 'static',
    });
    if (template.nodeType === NodeType.element) {
      modelRef.componentInstance.title = 'Select Form';
    }
    modelRef.componentInstance.types = [template.nodeType];
    modelRef.componentInstance.nodeTemplates = this.nodeTemplates;
    modelRef.componentInstance.selected = template.nodes.map(n => n.reference);
    modelRef.componentInstance.maxSelection =
      +template.behaviourType === BehaviourType.single ? 1 : 100;
    modelRef.result
      .then((result: NodeModel[]) => {
        this.store.dispatch(
          NodesActions.replaceAssignmentElementsRequest({
            assignmentId: this.assignment.id,
            nodeIds: result?.length ? result.map(n => n.id) : [],
            removeByNodeTemplateIds: [template.id],
          }),
        );
      })
      .catch(() => {});
  }

  openElementNodeView(item: { node: NodeModel; templateBehaviourType: BehaviourType }) {
    if (item?.templateBehaviourType === BehaviourType.single) {
      this.popoverService.open({
        content: NodeViewPopoverComponent,
        data: item,
      });
      return;
    }
    this.store.dispatch(
      NodeViewActions.openNodeView({
        nodeId: item?.node.reference?.id,
      }),
    );
    this.popoverService.open({
      content: NodeViewPopoverComponent,
    });
  }

  openElementAndWidgetPicker(template: NodeTemplateModel): void {
    this.nodeTemplates = [template];
    if (template?.nodes?.length) {
      this.setElementWidgetsEditStep();
    } else {
      const selected = template?.nodes?.map(n => n?.reference);
      this.setFormEditStep([template?.nodeType], selected);
    }
  }

  setFormEditStep(types: NodeType[], selected: NodeModel[]): void {
    this.types = types;
    // this.maxSelection = 1;
    // this.minSelection = 1;
    // this.selected = selected;
    // this.title = 'Select Form';
    // this.currentStep = 2;

    this.subscription.add(
      this.store
        .pipe(select(NodesSelectors.selectNodesFilteredByNodeTypeWithKeyword(this.types)))
        .subscribe(nodes => {
          this.updateList(nodes?.filter(f => f?.parentNodeId));
          // if there is only one form, select it automatically and go to step 3
          if (this.getRecommendedForms().length === 1) {
            this.store.dispatch(
              NodesActions.replaceAssignmentElementsRequest({
                assignmentId: this.assignment.id,
                nodeIds: this.getRecommendedForms().map(n => n?.model?.id),
                removeByNodeTemplateIds: this.isAddAnotherForm ? [] : [this.nodeTemplates[0]?.id],
                setSelectedLayoutAssignment: true,
              }),
            );
            // once this request is completed - it'll automatically set the step to 3
          } else {
            // if there are multiple forms, show the step 2
            this.maxSelection = 1;
            this.minSelection = 1;
            this.selected = selected;
            this.title = 'Select Form';
            this.currentStep = 2;
          }
        }),
    );
  }

  setElementWidgetsEditStep(): void {
    this.title = 'Edit Form';
    this.selected = [];
    this.currentStep = 3;

    const selectedTemplate: NodeTemplateModel = this.getSelectedNodeTemplate();

    if (selectedTemplate) {
      const widgets: NodeTemplateWidgetModel[] =
        selectedTemplate.nodes[0]?.reference?.widgets ?? [];
      this.widgets = widgets;
    }
  }

  getSelectedNodeTemplate(): NodeTemplateModel {
    return this.assignment?.elementGroups?.find(
      elementGroup => elementGroup?.id === this.nodeTemplates[0]?.id,
    );
  }

  changeWidgetValue(widget: NodeTemplateWidgetModel, value: any): void {
    // store the widget and value in a object array - so we can save all the changes when we press the save button
    // check whether widget change is already there
    const existingItem = this.widgetChanges.find(item => item?.widget?.id === widget?.id);
    if (existingItem) {
      existingItem.value = value;
    } else {
      this.widgetChanges.push({ widget, value });
    }
  }

  toFormGroup(widgets: NodeTemplateWidgetModel[]) {
    let group: any = {};

    widgets.forEach(w => {
      const validators = [];
      if (w.widget.mandatory) {
        if (w.widget.widgetType === WidgetType.address) {
          validators.push(addressFieldRequiredValidator);
        } else {
          validators.push(Validators.required);
        }
      }

      if (w.widget.widgetType === WidgetType.number) {
        validators.push(Validators.pattern(/^(?:-?\d+|-?\d{1,3}(?:,\d{3})+)?(?:\.\d+)?$/));
      } else if (w.widget.widgetType === WidgetType.email) {
        validators.push(Validators.email);
        validators.push(
          Validators.pattern(
            /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i,
          ),
        );
      } else if (w.widget.widgetType === WidgetType.phone) {
        validators.push(Validators.pattern(/^[0-9 .\-+()]+$/i));
      } else if (w.widget.widgetType === WidgetType.url) {
        validators.push(Validators.pattern(urlValidationPattern));
      }
      let widgetValue;
      if (w.widget.widgetType === WidgetType.currency) {
        widgetValue = (w.value || 0) / 100;
      } else {
        widgetValue = w.value || '';
      }

      group[w.id] = new UntypedFormControl(widgetValue, validators);
    });
    return new UntypedFormGroup(group);
  }

  public onSubmit() {
    if (this.currentStep === 2 && this.verifySelection() === false) {
      return;
    }

    if (this.currentStep === 2) {
      // save and show step 2 (from getSelectedLayoutAssignment listener)
      this.store.dispatch(
        NodesActions.replaceAssignmentElementsRequest({
          assignmentId: this.assignment.id,
          nodeIds: this.selected.map(n => n.id),
          removeByNodeTemplateIds: this.isAddAnotherForm ? [] : [this.nodeTemplates[0]?.id],
          setSelectedLayoutAssignment: true,
        }),
      );
      this.isAddAnotherForm = false;
    } else if (this.currentStep === 3) {
      // enable the form if it is disabled
      if (!this.formEditEnabled) {
        this.formEditEnabled = true;
        this.form.enable();
        return;
      }
      const selectedTemplate: NodeTemplateModel = this.getSelectedNodeTemplate();
      if (selectedTemplate) {
        this.widgetChanges?.forEach(item => {
          this.store.dispatch(
            NodeWidgetsActions.updateNodeWidgetRequest({
              nodeId: selectedTemplate?.nodes[0]?.id,
              widgetId: item?.widget?.id,
              widgetProps: { value: item?.value },
            }),
          );

          // change the widget value in the original widget array
          let existingWidget = selectedTemplate?.nodes[0]?.reference?.widgets?.find(
            eItem => eItem?.id === item?.widget?.id,
          );
          if (existingWidget) {
            existingWidget.value = item?.value;
          }
        });
        // clear the array
        this.widgetChanges = [];
      }
      this.currentStep = 1;
    }
  }

  public clearForm() {
    this.form.reset();
  }

  public addAnotherForm(): void {
    let types: NodeType[] = [];
    this.assignment?.elementGroups?.forEach((elementGroup: NodeTemplateModel) => {
      types.push(elementGroup?.nodeType);
    });

    // remove duplicates of types
    types = types.filter((v, i, a) => a.indexOf(v) === i);

    this.isAddAnotherForm = true;
    this.setFormEditStep(types, []);
  }

  public getRecommendedForms() {
    return this.list?.filter(
      item => this.nodeTemplates?.some(j => j.id === item?.model?.nodeTemplateId) == true,
    );
  }

  public getOtherForms() {
    return this.list?.filter(
      item =>
        this.assignment?.elementGroups?.some(j => j.id === item?.model?.nodeTemplateId) == false,
    );
  }

  printForm() {
    const div = document.getElementById('print-modal-' + this.assignment.id);
    const options = {
      background: 'white',
      scale: 3,
      width: div.scrollWidth,
      height: div.scrollHeight,
    };

    html2canvas(div, options)
      .then(canvas => {
        const img = canvas.toDataURL('image/PNG');
        let jsPdfOptions: jsPDFOptions = {
          orientation: 'portrait',
          unit: 'mm',
          format: 'a4',
          compress: true,
        };
        const doc = new jsPDF(jsPdfOptions);

        // Add image Canvas to PDF
        const pageHeight = 295;
        const imgWidth = 210;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;

        doc.addImage(img, 'PNG', 0, position, imgWidth, imgHeight);

        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(img, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        return doc;
      })
      .then(doc => {
        doc.save(this.pdfTitle);
      });
  }

  trackBy(index, widget) {
    return widget.id;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.subscription.unsubscribe();
  }

  protected readonly FilePaths = FilePaths;
}
