import { Action, createReducer, on } from '@ngrx/store';
import { initialNodeRateValuesState, NodeRateValuesState } from './node-rate-values.state';
import { NodeRateValuesActions } from './index';
import { NodeRateValueModel } from '../../core/models/node-rate-value.model';

const addToState = (
  state: NodeRateValuesState,
  nodeRate: NodeRateValueModel,
): NodeRateValuesState => {
  return {
    ...(state || {}),

    byId: {
      ...state.byId,
      [nodeRate.id]: nodeRate,
    },

    byNodeId: {
      ...state.byNodeId,
      [nodeRate.nodeId]: [
        ...(state.byNodeId[nodeRate.nodeId] || []).filter(rate => rate.id !== nodeRate.id),
        nodeRate,
      ],
    },
  };
};

const removeFromState = (
  state: NodeRateValuesState,
  nodeRate: NodeRateValueModel,
): NodeRateValuesState => {
  return {
    ...(state || {}),

    byId: {
      ...state.byId,
      [nodeRate.id]: undefined,
    },

    byNodeId: {
      ...state.byNodeId,
      [nodeRate.nodeId]: (state.byNodeId[nodeRate.nodeId] || []).filter(
        rate => rate.id !== nodeRate.id,
      ),
    },
  };
};

const reducer = createReducer(
  initialNodeRateValuesState,

  on(
    NodeRateValuesActions.loadNodeRateValuesFromNodeTree,
    (state, { nodeRateValues }): NodeRateValuesState => {
      return nodeRateValues.reduce((state, nodeRateValue) => {
        return addToState(state, nodeRateValue);
      }, state);
    },
  ),

  on(
    NodeRateValuesActions.addNodeRateValueSuccess,
    (state, { rateValue }): NodeRateValuesState => {
      return addToState(state, rateValue);
    },
  ),

  on(
    NodeRateValuesActions.updateNodeRateValueRequest,
    (state, { rateValueId, rateValueProps }): NodeRateValuesState => {
      return addToState(state, {
        ...state.byId[rateValueId],
        ...rateValueProps,
      });
    },
  ),

  on(
    NodeRateValuesActions.deleteNodeRateValueRequest,
    (state, { nodeId, rateValueId }): NodeRateValuesState => {
      return removeFromState(state, state.byId[rateValueId]);
    },
  ),
);

export function nodeRateValuesReducer(state: NodeRateValuesState, action: Action) {
  return reducer(state, action);
}
