<ngb-accordion #acc="ngbAccordion">
  <ngb-panel [disabled]="disabled" *ngIf="showOptions?.includes('Tags')">
    <ng-template ngbPanelTitle>
      <div class="row align-items-center">
        <div class="col-auto mx-2">
          <i class="fal fal-icon fa-tag"></i>
        </div>
        <div class="col text-truncate layout-title">
          <label class="m-1 text-truncate">Tags</label>
          <p class="m-1 text-truncate sub-text">Add primary tag groups</p>
        </div>
      </div>
    </ng-template>
    <ng-template ngbPanelContent>
      <app-template-group-tags
        [selectedTemplate]="selectedTemplate"
        [groupType]="WorkspaceGroupType.primaryTag"
        [groups]="primaryTagGroups"
      ></app-template-group-tags>
    </ng-template>
  </ngb-panel>

  <ngb-panel [disabled]="disabled" *ngIf="showOptions?.includes('Resources')">
    <ng-template ngbPanelTitle>
      <div class="row align-items-center">
        <div class="col-auto mx-2">
          <i class="fal fal-icon fa-users"></i>
        </div>
        <div class="col text-truncate layout-title">
          <label class="m-1 text-truncate">Resources</label>
          <p class="m-1 text-truncate sub-text">Select resource templates</p>
        </div>
      </div>
    </ng-template>
    <ng-template ngbPanelContent>
      <app-allowed-templates
        [type]="nodeTypes.asset"
        [selectedTemplate]="selectedTemplate"
        [selectedTemplates]="selectedTemplates"
        [selectedAssetTemplates]="selectedAssetTemplates"
        [selectedFormTemplates]="selectedFormTemplates"
      ></app-allowed-templates>
    </ng-template>
  </ngb-panel>

  <ngb-panel [disabled]="disabled" *ngIf="showOptions?.includes('Fields')">
    <ng-template ngbPanelTitle>
      <div class="row align-items-center">
        <div class="col-auto mx-2">
          <i class="fal fal-icon fa-input-text"></i>
        </div>
        <div class="col text-truncate layout-title">
          <label class="m-1 text-truncate">Fields</label>
          <p class="m-1 text-truncate sub-text">Add data fields</p>
        </div>
      </div>
    </ng-template>
    <ng-template ngbPanelContent>
      <app-template-checklists
        [selectedTemplate]="selectedTemplate"
        [groups]="groups"
      ></app-template-checklists>
    </ng-template>
  </ngb-panel>
</ngb-accordion>
