import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { TableComponent } from '../../table.component';
import { AbstractTableEditableCellComponent } from '../abstract-table-editable-cell.component';
import { FormControl } from '@angular/forms';
import { MonthDatePickerComponent } from '../../../../shared/components/month-date-picker/month-date-picker.component';

@Component({
  selector: 'app-z-table-editable-month-year',
  templateUrl: './table-editable-month-year.component.html',
  styleUrls: ['./table-editable-month-year.component.scss'],
})
export class TableEditableMonthYearComponent extends AbstractTableEditableCellComponent
  implements OnInit, AfterViewInit {
  @ViewChild('monthYearPickerTableEditor') monthYearPicker: MonthDatePickerComponent;

  monthYearCtrl = new FormControl(null);

  constructor(public table: TableComponent, public changeDetectorRef: ChangeDetectorRef) {
    super(table);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.cell.value = this.cell.value || '';
  }

  public ngAfterViewInit() {
    if (this.cell.value) {
      const [year, month] = this.cell.value.split('-');
      this.monthYearCtrl.setValue({ year: +year, month: +month });
    }
  }

  public onMonthYearSelection(date: NgbDate) {
    this.previousValue = this.cell.value;
    this.cell.value = `${date.year}-${('0' + date.month).slice(-2)}`;
    this.input && this.input.nativeElement.blur();
    this.monthYearPicker.closeCalendar();
    super.onEndEdit();
  }

  public changePeriodsValue() {
    this.monthYearPicker.openCalendar();
  }

  public onDateChange(date: any) {
    this.onMonthYearSelection(date);
  }
}
