import { AppState } from '../index';
import { createSelector } from '@ngrx/store';
import { SharedLinkMetaModel } from '../../core/models/shared-link-meta.model';
import { CardLayoutType } from '../../core/constants/card-layout-type';

const selectSharedViewState = (state: AppState) => state.sharedView;

export const selectSharedViewServerNodesById = createSelector(
  selectSharedViewState,
  state => state.serverNodesById,
);

export const selectSharedViewServerNodeIds = createSelector(
  selectSharedViewState,
  state => state.serverNodeIds,
);

export const selectSharedViewMeta = createSelector(selectSharedViewState, state => state.metaData);

export const selectActiveSharedView = createSelector(selectSharedViewState, state => state);

export const selectSharedViewHash = createSelector(selectSharedViewState, state => state.hash);

export const selectSharedViewServerNodeTemplatesById = createSelector(
  selectSharedViewState,
  state => state.serverNodeTemplatesById,
);

export const selectSharedViewServerNodeTemplateIds = createSelector(
  selectSharedViewState,
  state => state.serverNodeTemplateIds,
);

export const selectSharedViewServerGroupsById = createSelector(
  selectSharedViewState,
  state => state.serverGroupsById,
);

export const selectSharedViewServerGroupIds = createSelector(
  selectSharedViewState,
  state => state.serverGroupsIds,
);

export const selectSharedViewServerTagsById = createSelector(
  selectSharedViewState,
  state => state.serverTagsById,
);

export const selectSharedViewServerTagIds = createSelector(
  selectSharedViewState,
  state => state.serverTagsIds,
);

export const selectSharedViewServerWidgetsById = createSelector(
  selectSharedViewState,
  state => state.serverWidgetsById,
);

export const selectSharedViewServerWidgetsIds = createSelector(
  selectSharedViewState,
  state => state.serverWidgetsIds,
);

export const selectSharedViewServerNodeTemplates = createSelector(
  selectSharedViewServerNodeTemplatesById,
  selectSharedViewServerNodeTemplateIds,
  (byId, ids) => {
    return (ids || []).map(id => byId[id]);
  },
);

export const selectSharedViewServerGroups = createSelector(
  selectSharedViewServerGroupsById,
  selectSharedViewServerGroupIds,
  (byId, ids) => {
    return (ids || []).map(id => byId[id]);
  },
);

export const selectSharedViewServerTags = createSelector(
  selectSharedViewServerTagsById,
  selectSharedViewServerTagIds,
  (byId, ids) => {
    return (ids || []).map(id => byId[id]);
  },
);

export const selectSharedViewServerWidgets = createSelector(
  selectSharedViewServerWidgetsById,
  selectSharedViewServerWidgetsIds,
  (byId, ids) => {
    return (ids || []).map(id => byId[id]);
  },
);

export const selectSharedViewCalendarDateKeys = createSelector(
  selectSharedViewMeta,
  (meta: SharedLinkMetaModel) => {
    const startDate = meta?.startDate;
    const endDate = meta?.endDate;
    const showAwaiting = meta?.nullDate;
    if (startDate && endDate) {
      const dateKeys = [];
      // serverAssignmentsUtil.getCalendarDateKeysByRange(
      //   startDate,
      //   endDate,
      //   showAwaiting,
      // );
      return dateKeys;
    } else {
      return [];
    }
  },
);

export const selectSharedViewLayout = createSelector(
  selectSharedViewMeta,
  (meta: SharedLinkMetaModel) => {
    return meta && meta.assignmentLayout;
  },
);

export const selectSharedViewAssignmentViewType = createSelector(
  selectSharedViewMeta,
  (meta: SharedLinkMetaModel) => {
    return meta && meta.assignmentViewType;
  },
);

export const selectSharedViewAssignmentViewWidgetsNodeTypes = createSelector(
  selectSharedViewMeta,
  (meta: SharedLinkMetaModel) => {
    return meta && meta.assignmentViewWidgetsNodeTypes;
  },
);

export const selectSharedViewAssignmentViewTagsNodeTypes = createSelector(
  selectSharedViewMeta,
  (meta: SharedLinkMetaModel) => {
    return meta && meta.assignmentViewTagsNodeTypes;
  },
);

export const selectSharedViewAssignmentCardLayout = createSelector(
  selectSharedViewMeta,
  (meta: SharedLinkMetaModel) => {
    return CardLayoutType.oneColumn; // only use onColumn for the new Assignment Card style
  },
);

// export const selectSharedViewLink = createSelector(
//   selectSharedViewHash,
//   selectWorkspaceSharesByHash,
//   (hash: string, sharesByHash: { [hash: string]: SharedLinkModel }) => {
//     return sharesByHash?.[hash];
//   },
// );
//
// export const selectSharedViewLinkMetaData = createSelector(
//   selectSharedViewLink,
//   (link: SharedLinkModel) => link?.metaData,
// );
