<div
  class="date-picker_label_with_toggle"
  [ngClass]="{
    'date-picker_label_with_toggle--readonly': !editable
  }"
  (click)="changePeriodsValue()"
>
  <month-date-picker
    #monthYearPickerTableEditor
    [monthYearCtrl]="monthYearCtrl"
    (onDateChange)="onDateChange($event)"
  ></month-date-picker>
</div>
