import { Action, createReducer, on } from '@ngrx/store';
import { initialSharedViewState, SharedViewState } from './shared-view.state';
import { SharedViewActions } from './index';

const reducer = createReducer(
  initialSharedViewState,
  on(
    SharedViewActions.loadSharedViewSuccess,
    (state, { assignments, groups, share, templates, tags, widgets, title, hash }) => {
      let serverNodesById = {};
      let serverNodeIds = [];

      (assignments || []).forEach(node => {
        serverNodesById = {
          ...serverNodesById,
          [node.id]: node,
        };
        serverNodeIds = [...serverNodeIds, node.id];
      });

      let serverNodeTemplatesById = {};
      let serverNodeTemplateIds = [];
      (templates || []).forEach(template => {
        serverNodeTemplatesById = {
          ...serverNodeTemplatesById,
          [template.id]: template,
        };
        serverNodeTemplateIds = [...serverNodeTemplateIds, template.id];
      });

      // groups
      let serverGroupsById = {};
      let serverGroupIds = [];
      (groups || []).forEach(group => {
        serverGroupsById = {
          ...serverGroupsById,
          [group.id]: group,
        };
        serverGroupIds = [...serverGroupIds, group.id];
      });

      // tags
      let serverTagsById = {};
      let serverTagIds = [];
      (tags || []).forEach(tag => {
        serverTagsById = {
          ...serverTagsById,
          [tag.id]: tag,
        };
        serverTagIds = [...serverTagIds, tag.id];
      });

      // widgets
      let serverWidgetsById = {};
      let serverWidgetsIds = [];
      (widgets || []).forEach(widget => {
        serverWidgetsById = {
          ...serverWidgetsById,
          [widget.id]: widget,
        };
        serverWidgetsIds = [...serverWidgetsIds, widget.id];
      });

      return {
        ...state,
        title,
        hash: hash,
        serverNodesById: serverNodesById,
        serverNodeIds: serverNodeIds,
        metaData: share,
        serverNodeTemplatesById,
        serverNodeTemplateIds,
        serverGroupsById,
        serverGroupIds,
        serverTagsById,
        serverTagIds,
        serverWidgetsById,
        serverWidgetsIds,
      };
    },
  ),

  on(SharedViewActions.changeSharedViewMetaData, (state, props) => {
    return {
      ...state,
      metaData: {
        ...state.metaData,
        ...props,
      },
    };
  }),
);

export function sharedViewReducer(state: SharedViewState, action: Action) {
  return reducer(state, action);
}
