import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChildren,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../store';
import { NoDataTypeIds } from '../../../core/constants/no-data-settings';
import { SharedLinkModel } from '../../../core/models/shared-link.model';
import { selectWorkspaceSharedLinks } from '../../../store/workspace-shares/workspace-shares.selectors';
import { WorkspaceSharesActions } from '../../../store/workspace-shares';
import { SharedViewActions } from '../../../store/shared-view';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-share-links',
  templateUrl: 'share-links.component.html',
  styleUrls: ['share-links.component.scss'],
})
export class ShareLinksComponent implements OnInit, OnDestroy {
  @ViewChildren('sharedLinkElement', {
    read: ElementRef,
  })
  sharedLinkElements!: QueryList<ElementRef>;

  @Input()
  public instructions: string;

  @Input()
  public instructionsTemplate: TemplateRef<any>;

  @Input()
  public selectedLink: SharedLinkModel;

  @Input()
  public selectedLinkTitle: string;

  public noDataTypeIds = NoDataTypeIds;
  public readonly modalTitle = 'Share Links';
  public readonly iconUrl: string = 'assets/img/new-logo/Z-Chat.png'; // Default icon path

  private readonly subscription: Subscription;
  public sharedLinks: SharedLinkModel[] = [];
  public smallScreen = false;

  constructor(private store: Store<AppState>, private responsive: BreakpointObserver) {
    this.subscription = new Subscription();
  }

  public ngOnInit() {
    this.subscription.add(
      this.store.pipe(select(selectWorkspaceSharedLinks)).subscribe(sharedLinks => {
        this.sharedLinks = sharedLinks;
        if (this.selectedLinkTitle) {
          this.selectedLink = this.sharedLinks.find(
            link => link?.metaData?.title === this.selectedLinkTitle,
          );
          if (this.selectedLink) {
            this.store.dispatch(
              SharedViewActions.loadSharedViewRequest({
                hash: this.selectedLink.hash,
              }),
            );
            this.selectedLinkTitle = null;
            this.scrollToSelectedLink();
          }
        }
      }),
    );

    if (this.selectedLink) {
      this.store.dispatch(
        SharedViewActions.loadSharedViewRequest({
          hash: this.selectedLink.hash,
        }),
      );
      this.scrollToSelectedLink();
    }

    this.subscription.add(
      this.responsive.observe([Breakpoints.Small, Breakpoints.XSmall]).subscribe(result => {
        this.smallScreen = result.matches;
      }),
    );
  }

  public share(event) {
    this.store.dispatch(WorkspaceSharesActions.shareCurrentViewRequest({}));
  }

  selectLink(sharedLink: SharedLinkModel) {
    if (this.selectedLink?.id === sharedLink?.id) {
      this.selectedLink = null;
      return;
    }
    this.selectedLink = sharedLink;
    this.store.dispatch(
      SharedViewActions.loadSharedViewRequest({
        hash: this.selectedLink?.hash,
      }),
    );
  }

  private scrollToSelectedLink() {
    setTimeout(() => {
      if (this.selectedLink) {
        const selectedElement = this.sharedLinkElements.find(element => {
          const value = element.nativeElement.getAttribute('data-shared-link');
          return value === this.selectedLink?.hash;
        });

        if (selectedElement) {
          const container: any = document.querySelector('.shared-links--list');
          if (container) {
            container.scrollTo({
              top: selectedElement.nativeElement?.offsetTop - container?.offsetTop,
              behavior: 'smooth',
            });
          }
        }
      }
    }, 100);
  }

  removeLink(sharedLink: SharedLinkModel) {
    this.store.dispatch(WorkspaceSharesActions.removeSharedLinkRequest({ hash: sharedLink?.hash }));
    this.selectedLink = null;
  }

  goBack(): void {
    this.selectedLink = null;
  }

  trackBy(index, link) {
    return link.hash;
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
