<div class="inline-edit-input" [ngClass]="{ 'inline-edit-input--edit-mode': editMode }">
  <div
    *ngIf="!editMode"
    (click)="onClickText($event)"
    class="inline-edit-input__input inline-edit-input__input--text form-control"
  >
    <ng-container *ngTemplateOutlet="displayTemplate"></ng-container>
  </div>
  <div class="inline-edit-input__input-wrapper" *ngIf="editMode">
    <div appClickOutside (clickOutside)="onClickOutside()">
      <ng-container *ngTemplateOutlet="editTemplate"></ng-container>

      <div class="inline-edit-input__buttons" *ngIf="!hideBtns">
        <button
          type="submit"
          class="inline-edit-input__button btn btn-sm btn-primary"
          [disabled]="saveBtnDisabled"
          (click)="onSaveValue()"
        >
          {{ confirmBtnLabel ? confirmBtnLabel : 'Save' }}
        </button>
        <button
          type=" button"
          class="inline-edit-input__button btn btn-sm btn-outline-light layout-ml"
          (click)="onCancelValue()"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
