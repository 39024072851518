import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface Idata {
  month: number;
  year: number;
}
@Component({
  selector: 'month-date-picker',
  templateUrl: './month-date-picker.component.html',
  styleUrls: ['./month-date-picker.component.scss'],
})
export class MonthDatePickerComponent implements OnInit, OnDestroy {
  @ViewChild('calendarPanel') calendarPanel: NgbDropdown;

  private ngUnsubscribe = new Subject<void>();

  @Input() showFilterTitle: boolean = false;
  // Allow the input to be disabled, and when it is make it somewhat transparent.
  @Input() mask = 'mm-yyyy';
  @Input() container = 'body';
  @Input() monthYearCtrl = new FormControl(null);

  @Output() onDateChange = new EventEmitter();
  separator: string;
  monthFirst: boolean;
  place: number;
  currentYear: number = new Date().getFullYear();

  isyear: boolean = false;
  incr: number = 0;

  months: string[] = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  constructor() {
    this.separator = this.mask.replace(/m|y|M/gi, '');
    this.monthFirst = this.mask.indexOf('y') > 0;
    this.place = this.mask.indexOf(this.separator);
  }

  ngOnInit(): void {
    this.monthYearCtrl.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: Idata) => {
      this.onChange(data);
    });
  }

  // used from parent components via viewChilds
  openCalendar() {
    this.calendarPanel.open();
  }
  // used from parent components via viewChilds
  closeCalendar() {
    this.calendarPanel.close();
  }

  selectYearMonth($event, index: number) {
    let ctrlValue = this.monthYearCtrl.value || {};
    if (this.isyear) {
      $event.stopPropagation();
      ctrlValue.year = index + this.incr;
      this.monthYearCtrl.setValue(ctrlValue);
      this.isyear = false;
      this.incr = this.getIncr(ctrlValue.year);
    } else {
      ctrlValue.month = index + 1;
      if (!ctrlValue.year) {
        ctrlValue.year = this.currentYear;
      }
      this.monthYearCtrl.setValue(ctrlValue);
      // close the dropdown
      this.onDateChange.emit(ctrlValue);
      setTimeout(() => {
        this.closeCalendar();
      }, 100);
    }
  }

  showYear($event: any, show: boolean) {
    $event.stopPropagation();
    this.isyear = !this.isyear;
    let ctrlValue = this.monthYearCtrl.value || {};
    if (!ctrlValue.year) {
      ctrlValue.year = this.currentYear;
      this.monthYearCtrl.setValue(ctrlValue);
    }
  }

  addYear($event: any, incr: number) {
    $event.stopPropagation();
    let ctrlValue = this.monthYearCtrl.value || {};
    let year = this.isyear
      ? (ctrlValue.year ?? this.currentYear) + 10 * incr
      : (ctrlValue.year ?? this.currentYear) + incr;
    ctrlValue.year = year;
    this.monthYearCtrl.setValue(ctrlValue);
    this.incr = this.getIncr(year);
  }

  onChange(data: Idata) {
    this.incr = this.getIncr(data?.year);
  }

  getIncr(year: number): number {
    return year - (year % 10) - 1;
  }

  getDisplayValue(): string {
    const data = this.monthYearCtrl.value;
    return data?.month && data?.year
      ? this.monthFirst
        ? '' + this.months[data?.month - 1] + ' ' + data?.year
        : '' + data?.year + ' ' + this.months[data?.month - 1]
      : '';
  }

  ngOnDestroy(): void {
    // unsubscribe from all subscriptions
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
