import { WorkspaceUserModel } from '../models/workspace-user.model';
import { PermissionType } from '../constants/permission-type';
import { RoleType } from '../constants/role-type';

const canUser = (
  user: WorkspaceUserModel,
  permissionType: PermissionType,
  permissionObject?: any, // Could be used when more fine-grained permission control is needed
) => {
  if (user) {
    if (user.isOwner) {
      // workspace owner has all permissions
      return true;
    }

    //console.log('Can User:', user, permissionType);

    switch (permissionType) {
      case PermissionType.manageUsers:
        return user?.role === RoleType.role_admin;

      case PermissionType.editWorkspace:
        return user?.role === RoleType.role_admin || user?.role === RoleType.role_manager;

      case PermissionType.workspaceOwner:
        return user.isOwner;

      case PermissionType.deleteLayoutTemplate:
        return user.isOwner;

      default:
        return user?.permissions?.indexOf(permissionType) > -1;
    }
  }
  return false;
};

const permissionLabel = (permission: PermissionType): string => {
  switch (permission) {
    case PermissionType.manageUsers:
      return 'Manage User (legacy)';
    case PermissionType.editWorkspace:
      return 'Edit Workspace (legacy)';
    case PermissionType.workspaceOwner:
      return 'Workspace Owner (legacy)';

    case PermissionType.workspaceEditor:
      return 'Workspace Editor';
    case PermissionType.templateEditor:
      return 'Template Editor';
    case PermissionType.tagEditor:
      return 'Tag Editor';
    case PermissionType.urlsEditor:
      return 'Urls Editor';
    case PermissionType.archivedEditor:
      return 'Archived Editor';
    case PermissionType.userEditor:
      return 'User Editor';
    case PermissionType.integrationEditor:
      return 'Integration Editor';

    case PermissionType.projectEditor:
      return 'Project Editor';
    case PermissionType.assetEditor:
      return 'Asset Editor';
    case PermissionType.elementEditor:
      return 'Element Editor';
    case PermissionType.workspaceShare:
      return 'Workspace Share';

    case PermissionType.assignmentEditor:
      return 'Assignment Editor';
    case PermissionType.assignmentElementEditor:
      return 'Assignment Element Editor';
    case PermissionType.stampsEditor:
      return 'Stamps Editor';
    case PermissionType.figuresEditor:
      return 'Figures Editor';
    case PermissionType.checklistsEditor:
      return 'Checklists Editor';
    case PermissionType.diaryEditor:
      return 'Diary Editor';
    case PermissionType.timesheetsEditor:
      return 'Timesheets Editor';

    case PermissionType.workspaceView:
      return 'Workspace View';
    case PermissionType.urlsView:
      return 'Urls View';
    case PermissionType.projectView:
      return 'Project View';
    case PermissionType.assetView:
      return 'Asset View';
    case PermissionType.elementView:
      return 'Element View';
    case PermissionType.assignmentView:
      return 'Assignment View';
    case PermissionType.stampsView:
      return 'Stamps View';
    case PermissionType.figuresView:
      return 'Figures View';
    case PermissionType.checklistsView:
      return 'Checklists View';
    case PermissionType.diaryView:
      return 'Diary View';
    case PermissionType.timesheetsView:
      return 'Timesheets View';

    default:
      return 'Unknown';
  }
};

export { canUser, permissionLabel };
