<ng-template #stepInfo>
  <ng-container *ngIf="smallScreen && active">
    <button type="button" class="btn btn-secondary fal fa-chevron-left ml-2" (click)="goBack()">
      <!-- Screen reader only text for accessibility -->
      <span class="sr-only">Back</span>
    </button>
  </ng-container>
</ng-template>

<app-modal [title]="modalTitle" [iconUrl]="iconUrl" [stepTemplate]="stepInfo">
  <app-modal-instructions
    [html]="instructions"
    [template]="instructionsTemplate"
  ></app-modal-instructions>

  <div class="d-flex">
    <nav
      [ngStyle]="{
        display: smallScreen && active ? 'none' : 'block'
      }"
      ngbNav
      #nav="ngbNav"
      class="nav-tabs wl-tabs"
      [ngClass]="{ 'w-100': smallScreen }"
      orientation="vertical"
      [(activeId)]="active"
    >
      <!-- Tab 1 -->
      <ng-container ngbNavItem="1" [destroyOnHide]="false">
        <a ngbNavLink>
          <span>Your Links</span>
          <span class="badge badge-pill badge-secondary">{{
            groups?.length ? (groups | linksCountGroups) : 0
          }}</span>
        </a>
        <ng-template ngbNavContent>
          <div class="tab-content">
            <div class="title-col">
              <h2 class="text-center">Your Links</h2>
              <h6 class="text-center">Manage your link groups</h6>
            </div>

            <div class="group-list">
              <ng-container *ngIf="groups?.length; else noData">
                <app-link-group-view
                  *ngFor="let group of groups; trackBy: trackBy"
                  [group]="group"
                ></app-link-group-view>
              </ng-container>
              <ng-template #noData>
                <app-no-data [type]="noDataTypeIds.workspaceLinks"></app-no-data>
              </ng-template>
            </div>

            <div class="manage-links">
              <app-btn-fa iconName="cog" (click)="manageLinks($event)">Manage Links</app-btn-fa>
            </div>
          </div>
        </ng-template>
      </ng-container>

      <!-- Tab 2 -->
      <ng-container ngbNavItem="2" [destroyOnHide]="false">
        <a ngbNavLink>
          <span>Your Files</span>
          <span class="badge badge-pill badge-secondary">{{ 0 }}</span>
        </a>
        <ng-template ngbNavContent>
          <div class="tab-content">
            <div class="title-col">
              <h2 class="text-center">Your Files</h2>
              <h6 class="text-center">Manage and access your workspace files</h6>
            </div>
            <div class="group-list">
              <app-no-data [type]="noDataTypeIds.workspaceLinks"></app-no-data>
            </div>
          </div>
        </ng-template>
      </ng-container>

      <!-- Tab 3 -->
      <ng-container ngbNavItem="3" [destroyOnHide]="false">
        <a ngbNavLink>
          <span>Zedulr Support</span>
          <span class="badge badge-pill badge-secondary">{{ 0 }}</span>
        </a>
        <ng-template ngbNavContent>
          <div class="tab-content">
            <div class="title-col">
              <h2 class="text-center">Zedulr Support</h2>
              <h6 class="text-center">Get live support assistance</h6>
            </div>

            <div class="group-list">
              <div class="support-card">
                <div class="profile-section">
                  <img src="assets/svg/support.svg" alt="Profile Picture" />
                </div>

                <div class="support-info">
                  <h2>We're here to help</h2>
                  <p>
                    Still got questions about how our process works? Want to know what kind of
                    company suits best for you? Questions about running business in the US?
                  </p>
                  <a
                    href="https://direct.lc.chat/17772198/"
                    class="btn btn-lg btn-primary layout-mt-xl"
                    target="_blank"
                    >Live chat with support</a
                  >
                </div>
              </div>

              <div class="support-card">
                <div class="profile-section">
                  <img src="assets/svg/video.svg" alt="Profile Picture" />
                </div>

                <div class="support-info">
                  <h2>Visit knowledge Base</h2>
                  <p>
                    Still got questions about how our process works? Want to know what kind of
                    company suits best for you? Questions about running business in the US?
                  </p>
                  <a
                    href="https://www.motionshot.app/hub/zedulr"
                    class="btn btn-lg btn-primary layout-mt-xl"
                    target="_blank"
                    >Watch help guides</a
                  >
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container>

      <!-- Tab 4 -->
      <ng-container ngbNavItem="4" [destroyOnHide]="false">
        <a ngbNavLink>
          <span>Zedulr Release Notes</span>
          <span class="badge badge-pill badge-secondary">{{ 0 }}</span>
        </a>
        <ng-template ngbNavContent>
          <div class="tab-content">
            <div class="title-col">
              <h2 class="text-center">Zedulr Release Notes</h2>
              <h6 class="text-center">Latest updates and changes</h6>
            </div>

            <div class="release-notes">
              <div class="release-item">
                <div class="date">14 Sep</div>
                <div class="description">Navigation components</div>
                <div class="status added">added</div>
              </div>

              <div class="release-item">
                <div class="date">15 Sep</div>
                <div class="description">Logo and footer mark</div>
                <div class="status updated">updated</div>
              </div>

              <div class="release-item">
                <div class="date">16 Sep</div>
                <div class="description">Support for international roadmaps</div>
                <div class="status added">added</div>
              </div>

              <div class="release-item">
                <div class="date">17 Sep</div>
                <div class="description">Duplicate documents feature</div>
                <div class="status added">added</div>
              </div>

              <div class="release-item">
                <div class="date">18 Sep</div>
                <div class="description">Missing component instances</div>
                <div class="status removed">removed</div>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </nav>

    <div
      [ngStyle]="{
        display: smallScreen && !active ? 'none' : 'block'
      }"
      [ngbNavOutlet]="nav"
      class="ms-4 custom-content"
    ></div>
  </div>
</app-modal>
