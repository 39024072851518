<ng-template #stepInfo>
  <ng-container *ngIf="smallScreen">
    <button
      *ngIf="currentStep === 2 || currentStep === 3"
      type="button"
      class="btn btn-secondary fal fa-chevron-left ml-2"
      (click)="goBack()"
    >
      <!-- Screen reader only text for accessibility -->
      <span class="sr-only">Back</span>
    </button>
  </ng-container>
  <h6 step class="modal-sub-title hide-on-sm" *ngIf="currentStep === 3">
    {{ !formEditEnabled ? 'Previewing Form' : 'Editing Form' }}
  </h6>
</ng-template>

<!-- Main modal component setup -->
<app-modal [title]="title" [iconUrl]="iconUrl" [stepTemplate]="stepInfo">
  <!-- Instructions for the modal, can be customized or templated -->
  <app-modal-instructions
    [html]="instructions"
    [template]="instructionsTemplate"
  ></app-modal-instructions>

  <div class="container-c">
    <div class="row h-100 m-0">
      <ng-container *ngIf="!smallScreen; else smallScreenContent">
        <div class="col-4 left-col">
          <ng-container *ngTemplateOutlet="leftPanelContent"></ng-container>
        </div>
        <div class="col right-col">
          <div class="right-col--scroll-area">
            <div class="right-col--scroll-area-inner">
              <ng-container *ngIf="currentStep === 1">
                <app-no-data [type]="noDataTypeIds.SelectForm"></app-no-data>
              </ng-container>

              <!-- Content for the second step: item selection with search functionality -->
              <ng-container *ngIf="currentStep === 2">
                <ng-container *ngTemplateOutlet="rightPanelFormSelection"></ng-container>
              </ng-container>

              <!-- Content for the third step: tag editing and form submission -->
              <ng-container *ngIf="currentStep === 3">
                <ng-container *ngTemplateOutlet="widgetForm" [formGroup]="form"></ng-container>
              </ng-container>
            </div>
          </div>
          <ng-container *ngTemplateOutlet="rightPanelFooter"></ng-container>
          \
        </div>
      </ng-container>
      <ng-template #smallScreenContent>
        <div class="col-12 left-col--max-width" *ngIf="currentStep === 1">
          <ng-container *ngTemplateOutlet="leftPanelContent"></ng-container>
        </div>
        <div class="col-12 right-col--max-width" *ngIf="currentStep === 2 || currentStep === 3">
          <div class="right-col--scroll-area">
            <div class="right-col--scroll-area-inner">
              <!-- Content for the second step: item selection with search functionality -->
              <ng-container *ngIf="currentStep === 2">
                <ng-container *ngTemplateOutlet="rightPanelFormSelection"></ng-container>
              </ng-container>

              <!-- Content for the third step: tag editing and form submission -->
              <ng-container *ngIf="currentStep === 3">
                <ng-container *ngTemplateOutlet="widgetForm" [formGroup]="form"></ng-container>
              </ng-container>
            </div>
          </div>

          <ng-container *ngTemplateOutlet="rightPanelFooter"></ng-container>
        </div>
      </ng-template>
    </div>
  </div>
</app-modal>

<ng-template #elementFieldsTemp>
  <app-assignment-element-fields
    [nodeTemplates]="assignment?.elementGroups"
    [readonly]="false"
    [fieldNodeType]="customViewNodeType"
    [assignmentViewType]="viewType"
    [widgetsNodeTypes]="[]"
    [tagsNodeTypes]="[]"
    [cardLayout]="cardLayout"
    (editEvent)="openAssignmentElementsPopover($event)"
    (nodeViewEvent)="openElementNodeView($event)"
  ></app-assignment-element-fields>
</ng-template>

<ng-template #widgetForm>
  <div class="widget-form">
    <!-- Logo and title header -->
    <div class="container">
      <div class="column logo-column hide-on-sm">
        <span class="secured-header__logo-container">
          <img
            class="secured-header__logo secured-header__logo--desktop"
            [src]="filePathConstant.appLogo_2"
            alt="Zedulr"
          />
          <img
            class="secured-header__logo secured-header__logo--mobile"
            [src]="filePathConstant.appIcon"
            alt="Zedulr"
          />
        </span>
      </div>

      <div class="column title-column">
        <h2>{{ nodeTemplates[0]?.title }}</h2>
      </div>
    </div>
    <div>
      <div class="columns-container-header">
        <div class="left-column-header">
          <div class="row">
            <div class="label">Assignment:</div>
            <div class="value">{{ assignment?.reference?.title }}</div>
          </div>
          <div class="row">
            <div class="label">Completed by:</div>
            <div class="value">{{ user?.firstName + ' ' + user?.lastName }}</div>
          </div>
          <div class="row">
            <div class="label">{{ (assignment?.reference?.widgets)[0]?.widget?.title }}:</div>
            <div class="value">
              {{ (((assignment?.reference?.widgets)[0] | widgetValue) === 'Invalid date' ? '-' : ((assignment?.reference?.widgets)[0] | widgetValue) ?? '-') }}
            </div>
          </div>
          <div class="row">
            <div class="label">Last Edited:</div>
            <div class="value">{{ assignment?.editedDate | niceDate }}</div>
          </div>
        </div>
        <div class="right-column-header">
          <div class="row">
            <div class="label">{{ (assignment?.reference?.widgets)[1]?.widget?.title }}:</div>
            <div class="value">
              {{ (((assignment?.reference?.widgets)[1] | widgetValue) === 'Invalid date' ? '-' : ((assignment?.reference?.widgets)[1] | widgetValue) ?? '-') }}
            </div>
          </div>
          <div class="row">
            <div class="label">Activity Name:</div>
            <div class="value">{{ assignment?.nodeTemplate?.title }}</div>
          </div>
          <div class="row">
            <div class="label">{{ (assignment?.reference?.widgets)[2]?.widget?.title }}:</div>
            <div class="value">
              {{ (((assignment?.reference?.widgets)[2] | widgetValue) === 'Invalid date' ? '-' : ((assignment?.reference?.widgets)[2] | widgetValue) ?? '-') }}
            </div>
          </div>
          <div class="row">
            <div class="label">Activity Date:</div>
            <div class="value">{{ assignment?.date | momentDate: 'fullDate' }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="leaf-widget-list__list">
      <app-leaf-widget-item
        *ngFor="let widget of widgets; trackBy: trackBy"
        class="leaf-widget-list__item"
        [form]="form"
        [widget]="widget"
      >
        <app-widget-field
          [form]="form"
          [widget]="widget"
          [readonly]="!formEditEnabled"
          (changeValueEvent)="changeWidgetValue(widget, $event)"
        ></app-widget-field>
      </app-leaf-widget-item>
    </div>
    <p class="m-2 text-center" *ngIf="!widgets?.length">
      No widgets found!
    </p>
  </div>
</ng-template>

<ng-template #leftPanelContent>
  <div class="left-col--scroll-area">
    <ng-container *ngTemplateOutlet="elementFieldsTemp"></ng-container>
  </div>
  <div class="left-col--footer">
    <button type="button" class="btn btn-sm btn-primary" (click)="addAnotherForm()">
      <i class="fal fa-circle-plus layout-mr-sm"></i>
      Add another form
    </button>
  </div>
</ng-template>

<ng-template #rightPanelFormSelection>
  <div class="form-selection">
    <div class="row mx-0 item-picked__search-bar">
      <div class="col-12">
        <app-search-box
          [searchKeyword]="keywords"
          sizeType="sm"
          [inputHeight]="'60px'"
          (searchChanged)="search($event)"
        ></app-search-box>
      </div>
    </div>

    <ol class="item-picker__list item-picker__list--flex-column list-unstyled">
      <ng-container *ngIf="getRecommendedForms()?.length && !isAddAnotherForm">
        <span class="picker-label px-3">Assigned forms</span>
        <li
          *ngFor="let item of getRecommendedForms(); trackBy: trackBy"
          class="item-picker__list-item item-picker__list-item--flex-column"
        >
          <ng-container [ngSwitch]="item.model.nodeType">
            <div
              *ngSwitchCase="NodeType.asset"
              class="list-item__brick list-item__brick--node-asset"
              [ngClass]="[
                item.model.colorTheme
                  ? 'list-item__brick--' + (item.model.colorTheme | colorString)
                  : ''
              ]"
              (click)="toggle(item)"
            >
              <div class="list-item__left">
                <app-node-avatar
                  class="list-item__avatar"
                  [profile]="item.model.profile"
                  [profileIcon]="item.model?.nodeMeta?.profileIcon"
                  [initials]="item.model.title | toInitials"
                  [fullName]="item.model.title"
                  [size]="'sm'"
                  [colorTheme]="item.model.colorTheme"
                ></app-node-avatar>
              </div>
              <div class="list-item__center">
                <div class="list-item____title list-item____title--flex-column">
                  <app-truncate-text [value]="item.model.title" [limit]="20"></app-truncate-text>
                </div>
              </div>
              <div class="list-item__right">
                <app-selectable-input
                  [id]="item.model.id"
                  [checked]="item.selected"
                  [type]="maxSelection === 1 ? 'radio' : 'checkbox'"
                ></app-selectable-input>
              </div>
            </div>
            <div
              *ngSwitchDefault
              class="list-item__brick list-item__brick--flex-column list-item__brick--node-element"
              app-selectable-brick
              [color]="item.model.colorTheme"
              [icon]="item.model.icon"
              [label]="item.model.title"
              [isChecked]="item.selected"
              (click)="toggle(item)"
              [flexColumn]="true"
              [id]="item.model.id"
              [rightColTemplate]="checkBox"
            ></div>
            <ng-template #checkBox>
              <app-selectable-input
                [id]="item.model.id"
                [checked]="item.selected"
                [type]="maxSelection === 1 ? 'radio' : 'checkbox'"
              ></app-selectable-input>
            </ng-template>
          </ng-container>
        </li>
      </ng-container>

      <ng-container *ngIf="getOtherForms()?.length && isAddAnotherForm">
        <span class="picker-label px-3">Optional forms</span>
        <li
          *ngFor="let item of getOtherForms(); trackBy: trackBy"
          class="item-picker__list-item item-picker__list-item--flex-column"
        >
          <ng-container [ngSwitch]="item.model.nodeType">
            <div
              *ngSwitchCase="NodeType.asset"
              class="list-item__brick list-item__brick--node-asset"
              [ngClass]="[
                item.model.colorTheme
                  ? 'list-item__brick--' + (item.model.colorTheme | colorString)
                  : ''
              ]"
              (click)="toggle(item)"
            >
              <div class="list-item__left">
                <app-node-avatar
                  class="list-item__avatar"
                  [profile]="item.model.profile"
                  [profileIcon]="item.model?.nodeMeta?.profileIcon"
                  [initials]="item.model.title | toInitials"
                  [fullName]="item.model.title"
                  [size]="'sm'"
                  [colorTheme]="item.model.colorTheme"
                ></app-node-avatar>
              </div>
              <div class="list-item__center">
                <div class="list-item____title list-item____title--flex-column">
                  <app-truncate-text [value]="item.model.title" [limit]="20"></app-truncate-text>
                </div>
              </div>
              <div class="list-item__right">
                <app-selectable-input
                  [id]="item.model.id"
                  [checked]="item.selected"
                  [type]="maxSelection === 1 ? 'radio' : 'checkbox'"
                ></app-selectable-input>
              </div>
            </div>
            <div
              *ngSwitchDefault
              class="list-item__brick list-item__brick--flex-column list-item__brick--node-element"
              app-selectable-brick
              [color]="item.model.colorTheme"
              [icon]="item.model.icon"
              [label]="item.model.title"
              [isChecked]="item.selected"
              (click)="toggle(item)"
              [flexColumn]="true"
              [id]="item.model.id"
              [rightColTemplate]="checkBox"
            ></div>
            <ng-template #checkBox>
              <app-selectable-input
                [id]="item.model.id"
                [checked]="item.selected"
                [type]="maxSelection === 1 ? 'radio' : 'checkbox'"
              ></app-selectable-input>
            </ng-template>
          </ng-container>
        </li>
      </ng-container>
    </ol>
  </div>
</ng-template>

<ng-template #rightPanelFooter>
  <div class="right-col--footer">
    <div class="row m-0 w-100 footer-buttons">
      <div class="col-6 p-0">
        <!-- Toggle button for private view, shown only at the final step -->
        <ng-container *ngIf="currentStep === 3">
          <button
            *ngIf="!formEditEnabled"
            type="button"
            class="btn btn-sm btn-outline-light mr-2 hide-on-sm"
            (click)="printForm()"
          >
            <i class="fal fa-file-pdf layout-mr-sm"></i>
            Print Form
          </button>
          <ng-container *ngIf="formEditEnabled">
            <!-- <button
               class="btn btn-sm btn-outline-light text-left mr-2"
               [ngClass]="{
                 'layout-switch-btn-on': isPrivateView,
                 'layout-switch-btn-off': !isPrivateView
               }"
               (click)="makePublicOrPrivate()"
             >
               <span>Private view: {{ isPrivateView ? 'ON' : 'OFF' }}</span>
             </button>-->
            <button type="button" class="btn btn-sm btn-link" (click)="clearForm()">
              Clear form
            </button>
          </ng-container>
        </ng-container>

        <!-- Button to clear the form, available at the final step -->
      </div>

      <div class="col-6 p-0 d-flex justify-content-end">
        <!-- Cancel button to dismiss the modal -->
        <!-- <button
           *ngIf="currentStep === 2 || currentStep === 3"
           ngbAutofocus
           type="button"
           class="btn btn-sm btn-outline-light mr-2"
           aria-label="Close"
           (click)="activeModal.dismiss(false)"
         >
           Cancel
         </button>-->

        <!-- Next/Save button, text changes based on the current step -->
        <button
          *ngIf="currentStep === 2 || currentStep === 3"
          type="button"
          class="btn btn-sm btn-primary"
          aria-label="Close"
          (click)="onSubmit()"
          [disabled]="currentStep === 2 && !selected?.length"
        >
          {{ currentStep === 2 ? 'Next' : !formEditEnabled ? 'Edit Form' : 'Complete Form' }}
        </button>
      </div>
    </div>
  </div>
</ng-template>

<app-assignment-print-modal [pdfTableId]="assignment?.id" [showOnlyDynamicContent]="true">
  <ng-container *ngTemplateOutlet="widgetForm" [formGroup]="form"></ng-container>
</app-assignment-print-modal>
