import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppState } from '../../../store';
import {
  zTableProp,
  TableCell,
  TableColumn,
  TableColumnAlignment,
  TableGroupMoveEvent,
  TableRow,
  TableRowMoveEvent,
} from '../../table/table.common';
import { WorkspaceGroupModel } from '../../../core/models/workspace-group.model';
import { NodeModel } from '../../../core/models/node.model';
import { NodeUtils } from '../../../core/utils/node.util';
import { NodeTemplateModel } from '../../../core/models/node-template.model';
import { TableCellEditEvent, TableEditor } from '../../table/table-editors/table-editors.common';
import { TemplateTagModel } from '../../../core/models/template-tag.model';
import { NodeRateModel } from '../../../core/models/node-rate.model';
import { NodeRatesSelectors } from '../../../store/node-rates';
import { RateType } from '../../../core/constants/rate-type';
import { TimesheetsActions, TimesheetsSelectors } from '../../../store/timesheets';
import { TimesheetModel } from '../../../core/models/timesheet.model';
import { IconType } from '../../../core/constants/icon-type';
import { CostTableSettings } from '../../../core/models/cost-table-settings.model';
import * as moment from 'moment';
import { NodesActions } from '../../../store/nodes';

const FieldTypes = {
  primaryTag: 'primaryTag',
  stampTag: 'stampTag',
  assets: 'assets',
  elements: 'elements',
  checkbox: 'checkbox',
  calculation: 'calculation',
};

@Component({
  selector: 'app-z-table-assignment-timesheets',
  templateUrl: './assignment-timesheets-table.component.html',
})
export class AssignmentTimesheetsTableComponent implements OnInit, OnChanges, OnDestroy {
  private readonly subscription: Subscription = new Subscription();

  public readonly zTableProp = zTableProp;

  @Input()
  public assignments: NodeModel[] = [];
  @Input()
  public assignmentTitle: string;
  @Input()
  public assignmentIcon: IconType;

  @Input()
  public readonly: boolean;

  @Input()
  public settings: CostTableSettings;

  private newTimeSheetInfoArray: {
    id: string;
    values: { finalValue?: number; value?: number };
  }[] = [];

  public tableColumns: TableColumn[] = [];
  public tableBody: TableRow[] = [];

  public ratesById: { [id: string]: NodeRateModel } = {};
  public ratesByNodeId: { [id: string]: NodeRateModel[] } = {};

  public timesheetsByNodeId: { [id: string]: TimesheetModel[] } = {};

  constructor(private store: Store<AppState>) {
    this.readonly = true;

    this.subscription.add(
      this.store.pipe(select(NodeRatesSelectors.selectNodeRatesState)).subscribe(ratesState => {
        this.ratesById = ratesState.byId || {};
        this.ratesByNodeId = ratesState.byNodeId || {};
        this.onTableUpdate();
      }),
    );

    // Timesheets are included with nodes, however, timesheet changes won't trigger a node state update
    // So we'll subscribe to timesheet changes
    this.subscription.add(
      this.store
        .pipe(select(TimesheetsSelectors.selectTimesheetsByNodeId))
        .subscribe(timesheetsByNodeId => {
          this.timesheetsByNodeId = timesheetsByNodeId || {};
          this.onTableUpdate();
        }),
    );
  }

  public ngOnInit() {
    this.resetTable();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.assignments &&
      changes.assignments.previousValue !== changes.assignments.currentValue
    ) {
      this.resetTable();
      return;
    }
    if (changes.readonly && changes.readonly.previousValue !== changes.readonly.currentValue) {
      this.resetTable();
      return;
    }
    if (changes.settings && changes.settings.previousValue !== changes.settings.currentValue) {
      this.resetTable();
      return;
    }
    this.onTableUpdate();
  }

  public resetTable(): void {
    this.onTableColumns();
    this.onTableUpdate();
  }

  public onTableStartEdit(event: TableCellEditEvent) {
    return;
  }

  public onTableEndEdit(event: TableCellEditEvent) {
    if (event.cell.value === event.previousValue) {
      return;
    }

    switch (event.field) {
      case 'startTime':
      case 'endTime':
      case 'finalValue':
      case 'value':
      case 'status':
      case 'reportPeriod':
        {
          const assignment = event.cell.options.assignment;
          const asset = event.cell.options.asset;
          const timesheet = event.cell.options?.timesheet;

          if (timesheet && timesheet.id) {
            this.store.dispatch(
              TimesheetsActions.updateTimesheetRequest({
                timesheetId: timesheet.id,
                timesheetProps: {
                  [event.field]: event.cell.value,
                },
              }),
            );
          } else {
            // before doing add -> check whether edit is related to units or rate
            if (event?.field === 'finalValue' || event?.field === 'value') {
              this.updateTimeSheetInfo(event, asset, assignment);
              return;
            }
            // if not continue
            this.store.dispatch(
              TimesheetsActions.addTimesheetRequest({
                assetId: asset.id,
                assignmentId: assignment.id,
                timesheetProps: {
                  [event.field]: event.cell.value,
                },
              }),
            );
          }
        }
        break;

      default:
        break;
    }

    return;
  }

  private updateTimeSheetInfo(event, asset, assignment): void {
    // maintain an array to store the saved values until both are filled.
    const extItem = this.newTimeSheetInfoArray?.find(a => a?.id === event?.row?.id);
    // if we have item already store in the newTimeSheetInfoArray array.
    if (extItem) {
      if (event?.field === 'finalValue') {
        extItem.values.finalValue = event.cell.value;
      } else if (event?.field === 'value') {
        extItem.values.value = event.cell.value;
      }

      // once both the fields are filled - send both the field to the endpoint
      if (extItem?.values?.finalValue && extItem?.values?.value) {
        this.store.dispatch(
          TimesheetsActions.addTimesheetRequest({
            assetId: asset.id,
            assignmentId: assignment.id,
            timesheetProps: {
              finalValue: extItem.values.finalValue,
              value: extItem.values.value,
            },
          }),
        );
        // remove the items from the array
        this.newTimeSheetInfoArray = [];
      }
    } else {
      // if new row edit
      const newTimeSheetInfo = {
        id: event?.row?.id, // unique id
        values: {
          finalValue: event?.field === 'finalValue' ? event.cell.value : null,
          value: event?.field === 'value' ? event.cell.value : null,
        },
      };
      this.newTimeSheetInfoArray.push(newTimeSheetInfo);
    }
  }

  public onTableGroupMove(event: TableGroupMoveEvent) {
    return;
  }

  public onTableRowMove(event: TableRowMoveEvent) {
    return;
  }

  public onTableNewRow(event) {
    return;
  }

  public onTableInsert(row: TableRow, index: number) {
    return;
  }

  public onTableCopy(row: TableRow, index: number) {
    return;
  }

  public onTableDelete(row: TableRow, index: number) {
    return;
  }

  public onTableColumns() {
    this.tableColumns = [
      {
        id: 'sortIndex',
        label: '#',
        alignment: TableColumnAlignment.Center,
        sortable: true,
        collapsed: this.settings.sortIndex,
        editor: TableEditor.RowNumber,
        editable: false,
        fixedWidth: true,
      } as TableColumn,
      {
        id: 'lock',
        label: 'Lock',
        colSpan: 1,
        alignment: TableColumnAlignment.Center,
        sortable: false,
        collapsed: this.settings.lock,
        groupable: false,
        editor: TableEditor.AssignmentLock,
        editable: true,
        fixedWidth: true,
      } as TableColumn,
      {
        id: 'menu',
        label: 'Menu',
        colSpan: 1,
        alignment: TableColumnAlignment.Center,
        sortable: false,
        collapsed: this.settings.menu,
        groupable: false,
        editor: TableEditor.AssignmentMenu,
        editable: true,
        fixedWidth: true,
      } as TableColumn,
      {
        id: 'date',
        label: 'Date',
        colSpan: 2,
        alignment: TableColumnAlignment.Left,
        sortable: true,
        collapsed: this.settings.date,
        groupable: true,
        editor: TableEditor.Date,
        editable: false,
        fixedWidth: true,
      } as TableColumn,
      {
        id: 'projectTitle',
        label: 'Assignment',
        colSpan: 4,
        alignment: TableColumnAlignment.Left,
        sortable: true,
        collapsed: this.settings.projectTitle,
        groupable: true,
        editor: TableEditor.Textarea,
        editable: false,
        fixedWidth: true,
      } as TableColumn,
      {
        id: 'reportPeriod',
        label: 'Report Period',
        colSpan: 3,
        sortable: true,
        editor: TableEditor.MonthYear,
        editable: true,
      } as TableColumn,
      {
        id: 'activityLayout',
        label: 'Activity Layout',
        colSpan: 4,
        alignment: TableColumnAlignment.Left,
        sortable: true,
        collapsed: false,
        groupable: true,
        // editor: TableEditor.Textarea,
        editable: false,
        fixedWidth: true,
      } as TableColumn,
    ];

    const firstAssignment = this.assignments[0];
    if (firstAssignment != null) {
      // Primary tags
      if (firstAssignment?.nodeTemplate?.allowPrimaryTags) {
        (firstAssignment.primaryTagGroups || []).forEach((group: WorkspaceGroupModel) => {
          this.tableColumns = [
            ...this.tableColumns,
            {
              id: `${FieldTypes.primaryTag}:${group.id.toString()}`,
              label: group.title,
              colSpan: 3,
              alignment: TableColumnAlignment.Left,
              sortable: true,
              collapsed: this.settings.primaryTags,
              groupable: true,
              editor: TableEditor.AssignmentTag,
              editable: true,
              groupLabel: (tag: TemplateTagModel): string => {
                return tag && tag.tag ? tag.tag.title : '';
              },
              sortValue: (tag: TemplateTagModel): string => {
                return tag && tag.tag ? tag.tag.title : '';
              },
            } as TableColumn,
          ];
        });
      }

      this.tableColumns = [
        ...this.tableColumns,
        {
          id: 'asset_profile',
          label: 'Profile',
          colSpan: 1,
          alignment: TableColumnAlignment.Left,
          sortable: false,
          collapsed: this.settings.assetProfile,
          groupable: true,
          editor: TableEditor.AssignmentAssets,
          editable: false,
          groupLabel: (nodes: NodeModel[]): string => {
            return nodes.reduce((label, n) => {
              return label + n.title + ' ';
            }, '');
          },
        } as TableColumn,

        {
          id: 'asset_name',
          label: 'Name',
          colSpan: 3,
          alignment: TableColumnAlignment.Left,
          sortable: true,
          collapsed: this.settings.assetName,
          groupable: true,
          editor: TableEditor.Text,
          editable: false,
        } as TableColumn,

        {
          id: 'startTime',
          label: 'Start Time',
          colSpan: 2,
          alignment: TableColumnAlignment.Right,
          sortable: true,
          collapsed: this.settings.startTime,
          groupable: false,
          editor: TableEditor.Time,
          editable: true,
        } as TableColumn,

        {
          id: 'endTime',
          label: 'Finish Time',
          colSpan: 2,
          alignment: TableColumnAlignment.Right,
          sortable: true,
          collapsed: this.settings.endTime,
          groupable: false,
          editor: TableEditor.Time,
          editable: true,
        } as TableColumn,

        {
          id: 'timeHours',
          label: 'Timesheet Hrs',
          colSpan: 2,
          alignment: TableColumnAlignment.Right,
          sortable: true,
          collapsed: this.settings.timeHours,
          groupable: false,
          editor: TableEditor.Number,
          editable: false,
        } as TableColumn,
      ];

      this.tableColumns = [
        ...this.tableColumns,
        {
          id: 'value',
          label: 'Item',
          colSpan: 6,
          sortable: true,
          collapsed: this.settings.value,
          alignment: TableColumnAlignment.Left,
          editor: TableEditor.WorkspaceRate,
          editable: true,
          groupable: true,
          groupLabel: (id: string): string => {
            const rate = this.ratesById[id];
            return rate ? rate.title : '';
          },
        } as TableColumn,
        {
          id: 'rate',
          label: 'Rate($)',
          colSpan: 2,
          sortable: true,
          collapsed: this.settings.value,
          alignment: TableColumnAlignment.Right,
          editor: TableEditor.Number,
          editable: false,
          groupable: false,
        } as TableColumn,
        {
          id: 'unit',
          label: 'Units',
          colSpan: 2,
          sortable: true,
          collapsed: this.settings.value,
          alignment: TableColumnAlignment.Right,
          editor: TableEditor.Text,
          editable: false,
          groupable: false,
        } as TableColumn,
        {
          id: 'finalValue',
          label: 'Daily Qty',
          colSpan: 2,
          alignment: TableColumnAlignment.Right,
          sortable: true,
          collapsed: this.settings.finalValue,
          groupable: false,
          editor: TableEditor.Number,
          editable: true,
        } as TableColumn,
        {
          id: 'timeTotal',
          label: 'Total',
          colSpan: 2,
          alignment: TableColumnAlignment.Right,
          sortable: true,
          collapsed: this.settings.timeTotal,
          groupable: false,
          editor: TableEditor.Number,
          editable: false,
          includeTotal: true,
        } as TableColumn,
      ];
    }
  }

  public onTableUpdate() {
    this.tableBody = [];

    this.assignments.forEach(assignment => {
      const editable = assignment.readOnly == false;
      if (!assignment.assetGroups.some(group => group.allowRates)) {
        return;
      }

      (assignment.assetGroups || []).forEach((group: NodeTemplateModel) => {
        if (group.allowRates == false) {
          return;
        }
        (group.nodes || []).forEach(asset => {
          let timesheet = (this.timesheetsByNodeId[asset.id] || []).find(
            t => t.assignmentId == assignment.id,
          );
          let assetRates: NodeRateModel;
          if (timesheet) {
            assetRates = timesheet?.value ? this.ratesById[timesheet?.value] : null;
            // Parse the string into a Moment object
            if (timesheet?.startTime) {
              const dateTime = moment(timesheet.startTime, 'YYYY-MM-DD HH:mm:ss');
              // Format the Moment object to exclude the date part
              const timeString = dateTime.format('HH:mm:ss');
              timesheet = {
                ...timesheet,
                startTime: timeString,
              };
            }

            // Parse the string into a Moment object
            if (timesheet?.endTime) {
              const dateTime = moment(timesheet.endTime, 'YYYY-MM-DD HH:mm:ss');
              // Format the Moment object to exclude the date part
              const timeString = dateTime.format('HH:mm:ss');
              timesheet = {
                ...timesheet,
                endTime: timeString,
              };
            }
          }

          const row = {
            id: `${assignment.id}-${asset.id}`,
            columns: [],
          };

          row.columns = [
            {
              value: assignment.sortIndex,
              alignment: TableColumnAlignment.Center,
            } as TableCell,

            // Lock
            {
              value: assignment.readOnly,
              alignment: TableColumnAlignment.Center,
              options: {
                assignment,
              },
            } as TableCell,

            // Menu
            {
              value: null,
              alignment: TableColumnAlignment.Center,
              options: {
                assignment,
              },
            } as TableCell,

            {
              value:
                assignment.date != NodeUtils.awaitingAssignmentsDateKey ? assignment.date : null,
              alignment: TableColumnAlignment.Left,
            } as TableCell,

            {
              value: assignment.reference.title,
              alignment: TableColumnAlignment.Left,
            } as TableCell,

            // period
            {
              value: timesheet?.reportPeriod ?? '',
              alignment: TableColumnAlignment.Left,
              options: {
                assignment,
                group,
                asset,
                timesheet,
              },
            } as TableCell,
            {
              value: assignment.nodeTemplate.title,
              alignment: TableColumnAlignment.Left,
            } as TableCell,
          ];

          // Primary tags
          if (assignment?.nodeTemplate?.allowPrimaryTags) {
            (assignment.primaryTagGroups || []).forEach((group: WorkspaceGroupModel) => {
              const tag = group.tags && group.tags.length ? group.tags[0] : null;
              row.columns = [
                ...row.columns,
                {
                  value: tag,
                  alignment: TableColumnAlignment.Left,
                  options: {
                    assignment,
                    group,
                    asset,
                    timesheet,
                  },
                } as TableCell,
              ];
            });
          }

          // Assets
          row.columns = [
            ...row.columns,
            {
              value: [asset],
              alignment: TableColumnAlignment.Left,
              options: {
                assignment,
                group,
                asset,
                timesheet,
              },
            } as TableCell,

            {
              value: asset.reference?.title,
              alignment: TableColumnAlignment.Left,
              options: {
                assignment,
                group,
                asset,
                timesheet,
              },
            } as TableCell,

            {
              value: timesheet?.startTime, // Start Time
              alignment: TableColumnAlignment.Right,
              options: {
                assignment,
                group,
                asset,
                timesheet,
              },
              editable,
            } as TableCell,

            {
              value: timesheet?.endTime, // End Time
              alignment: TableColumnAlignment.Right,
              options: {
                assignment,
                group,
                asset,
                timesheet,
              },
              editable,
            } as TableCell,

            {
              value: this.calculateTotalHours(timesheet), // Hours
              alignment: TableColumnAlignment.Right,
              editable: false,
            } as TableCell,
          ];

          let selectedRate: any[] = [];
          if (timesheet?.value) {
            selectedRate = (this.ratesByNodeId[asset.reference?.id] || [])
              ?.filter(fItem => +fItem?.id === timesheet?.value)
              ?.map(rate => {
                return {
                  value: +rate?.id,
                  label: rate?.title,
                  type: RateType[rate?.rateType],
                  units: rate?.units,
                  amount: (rate?.value || 0) / 100,
                };
              });
          }

          row.columns = [
            ...row.columns,
            {
              value: timesheet?.value || 0,
              alignment: TableColumnAlignment.Right,
              options: {
                list: (this.ratesByNodeId[asset.reference?.id] || []).map(rate => {
                  return {
                    value: +rate?.id,
                    label: rate?.title,
                    type: RateType[rate?.rateType],
                    units: rate?.units,
                    amount: (rate?.value || 0) / 100,
                  };
                }),
                assignment,
                group,
                asset,
                timesheet,
              },
              editable,
            } as TableCell,
            {
              value: selectedRate?.length ? selectedRate[0]?.amount : 0, // Rate($)
              alignment: TableColumnAlignment.Right,
              editable,
            } as TableCell,
            {
              value: selectedRate?.length ? selectedRate[0]?.units : null, // units
              alignment: TableColumnAlignment.Right,
              editable,
            } as TableCell,
            {
              value: timesheet?.finalValue || 0, // qty
              alignment: TableColumnAlignment.Right,
              options: {
                assignment,
                group,
                asset,
                timesheet,
              },
              editable,
            } as TableCell,
            {
              value:
                (timesheet?.finalValue || 0) *
                (assetRates?.value ? (assetRates?.value || 0) / 100 : 0), // Total
              alignment: TableColumnAlignment.Right,
              editable: false,
            } as TableCell,
          ];

          this.tableBody.push(row);
        });
      });
    });
  }

  private calculateTotalHours(timesheet: TimesheetModel): number {
    if (!timesheet?.startTime || !timesheet?.endTime) {
      return 0;
    }

    const startTime = moment(timesheet.startTime, 'HH:mm:ss');
    const endTime = moment(timesheet.endTime, 'HH:mm:ss');

    // Check if endTime is before startTime and adjust by adding 24 hours to endTime
    if (endTime.isBefore(startTime)) {
      endTime.add(24, 'hours');
    }

    const duration = moment.duration(endTime.diff(startTime));
    return duration.asHours();
  }

  public isAllAssignmentsLocked(status = true): boolean {
    return this.assignments.every(x => x?.readOnly === status);
  }

  public isAssignmentLockIconVisible(): boolean {
    return this.assignments.every(x => x?.readOnly) || this.assignments.every(x => !x?.readOnly);
  }

  public onLockUnlockAssignmentStatus(event: any): void {
    const lockStatus = this.assignments[0]?.readOnly;
    for (const assignment of this.assignments) {
      this.store.dispatch(
        NodesActions.updateAssignmentReadonlyRequest({
          nodeId: assignment.id,
          readonly: !lockStatus,
        }),
      );
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
